import { convertDatesToISO } from '@/config/aux'
import { AlarmLog } from '../models/alarmLog'
import { AlarmLogStateType } from './state'
import axios from 'axios'

export interface AlarmLogActionsType {
  getAlarmLogs(): Promise<void>
  // getAlarmLogById(id: string): Promise<AlarmLog | undefined>
  getAlarmLogsByAlarmId(
    alarmId: string,
    startDate: string,
    endDate: string,
    gtm: string,
  ): Promise<AlarmLog[]>
  getAlarmLogsByAlarmIdAndLimit(alarmId: string, limit: number, gtm: string): Promise<AlarmLog[]>
  getLastAlarmLogByAlarmId(alarmId: string, gtm: string): Promise<AlarmLog | undefined>
  postAlarmLog(alarmLog: AlarmLog): Promise<void>
  putAlarmLog(alarmLog: AlarmLog): Promise<void>
  deleteAlarmLog(id: string): Promise<void>
}

export const AlarmLogActions = {
  async getAlarmLogs(this: AlarmLogStateType) {
    try {
      this.alarmLogs = []
      const query = 'https://aqfcore-api.aquaflow.cl/alarmlogs'
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((alarmLog: AlarmLog) => {
            this.alarmLogs.push(alarmLog)
          })
        }
      })
    } catch (error) {
      console.error('Error getting AlarmLogs', error)
    }
  },
  // async getAlarmLogById(this: AlarmLogStateType, id: string): Promise<AlarmLog | undefined> {
  //   try {
  //     let alarmLog: AlarmLog | undefined = undefined
  //     const query = `https://aqfcore-api.aquaflow.cl/alarmlogs/${id}`
  //     await axios.get(query).then(response => {
  //       if (response.status === 200) {
  //         alarmLog = response.data
  //       }
  //     })
  //     return alarmLog
  //   } catch (error) {
  //     console.error('Error getting AlarmLogs', error)
  //     return undefined
  //   }
  // },
  async getAlarmLogsByAlarmId(
    this: AlarmLogStateType,
    alarmId: string,
    startDate: string,
    endDate: string,
    gtm: string,
  ): Promise<AlarmLog[]> {
    try {
      const alarmLogs: AlarmLog[] = []
      const gtmOffset = parseInt(gtm)
      const query = `https://aqfcore-api.aquaflow.cl/alarmlogs/alarm/${alarmId}?startDate=${startDate}&endDate=${endDate}`
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((alarmLog: AlarmLog) => {
            const timestamp = new Date(alarmLog.timestamp)
            timestamp.setHours(timestamp.getHours() + gtmOffset)
            alarmLog.timestamp = timestamp.toISOString()
            alarmLogs.push(alarmLog)
          })
        }
      })
      return alarmLogs
    } catch (error) {
      console.error('Error getting AlarmLogs', error)
      return []
    }
  },
  async getAlarmLogsByAlarmIdAndLimit(
    this: AlarmLogStateType,
    alarmId: string,
    limit: number,
    gtm: string,
  ): Promise<AlarmLog[]> {
    try {
      const alarmLogs: AlarmLog[] = []
      const gtmOffset = parseInt(gtm)
      const query = `https://aqfcore-api.aquaflow.cl/alarmlogs/alarm/${alarmId}?limit=${limit}`
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((alarmLog: AlarmLog) => {
            const timestamp = new Date(alarmLog.timestamp)
            timestamp.setHours(timestamp.getHours() + gtmOffset)
            alarmLog.timestamp = timestamp.toISOString()
            alarmLogs.push(alarmLog)
          })
        }
      })
      return alarmLogs
    } catch (error) {
      console.error('Error getting AlarmLogs', error)
      return []
    }
  },
  async getLastAlarmLogByAlarmId(
    this: AlarmLogStateType,
    alarmId: string,
    gtm: string,
  ): Promise<AlarmLog | undefined> {
    try {
      let alarmLog: AlarmLog | undefined = undefined
      const gtmOffset = parseInt(gtm)
      const query = `https://aqfcore-api.aquaflow.cl/alarmlogs/alarm/${alarmId}/last`
      await axios.get(query).then(response => {
        if (response.status === 200) {
          alarmLog = response.data
          if (alarmLog !== undefined) {
            const timestamp = new Date(alarmLog.timestamp)
            timestamp.setHours(timestamp.getHours() + gtmOffset)
            alarmLog.timestamp = timestamp.toISOString()
          }
        }
      })
      return alarmLog
    } catch (error) {
      console.error('Error getting AlarmLogs', error)
      return undefined
    }
  },
  async postAlarmLog(this: AlarmLogStateType, alarmLog: AlarmLog) {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/alarmlogs'
      const data = convertDatesToISO(alarmLog)

      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.alarmLogs.push(alarmLog)
        }
      })
    } catch (error) {
      console.error('Error posting AlarmLog', error)
    }
  },
  async putAlarmLog(this: AlarmLogStateType, alarmLog: AlarmLog) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/alarmlogs`
      const data = convertDatesToISO(alarmLog)
      //const dataJson = JSON.stringify(data)

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.alarmLogs = this.alarmLogs.map(a => (a.id === alarmLog.id ? alarmLog : a))
          }
        })
    } catch (error) {
      console.error('Error updating AlarmLog', error)
    }
  },
  async deleteAlarmLog(this: AlarmLogStateType, id: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/alarmlogs/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.alarmLogs = this.alarmLogs.filter(a => a.id !== alarmLog.id)
        }
      })
    } catch (error) {
      console.error('Error deleting AlarmLog', error)
    }
  },
}
