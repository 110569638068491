import { UserStationPreferenceStateType } from './state'
import { useStationFileStore } from '../../StationFile/store'

export interface UserStationPreferenceGettersType {
  getUserStationPreferenceByStationId: (
    state: UserStationPreferenceStateType,
  ) => (stationId: string) => UserStationPreferenceStateType['userStationPreference']
}

export const UserStationPreferenceGetters = {
  getUserStationPreferenceByStationId:
    (state: UserStationPreferenceStateType) => (stationId: string) => {
      const stationFileStore = useStationFileStore()
      const stationFileId = stationFileStore.getStationFileIdByStationId(stationId)
      return state.userStationPreferences.find(
        userStationPreference => userStationPreference.stationFileId === stationFileId,
      )
    },
}
