import { AuthStateType } from './authState'

export interface AuthGettersType {
  getUser: (state: AuthStateType) => AuthStateType['user']
  isLoggedIn: (state: AuthStateType) => boolean
  getUserProfile: (state: AuthStateType) => AuthStateType['userProfile']
}

export const AuthGetters = {
  getUser: (state: AuthStateType) => state.user,
  isLoggedIn: (state: AuthStateType) => state.user !== null,
  getUserProfile: (state: AuthStateType) => state.userProfile,
}
