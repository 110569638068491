import { StationFileStateType } from './state'
import { Column } from '../models/stationFile'
export interface StationFileGettersType {
  getFilenameStationFileById: (
    state: StationFileStateType,
  ) => (id: string) => StationFileStateType['stationFile']
  getStationFileByStationId: (
    state: StationFileStateType,
  ) => (id: string) => StationFileStateType['stationFile']
  getStationFileIdByStationId: (state: StationFileStateType) => (id: string) => string
  getOrderedColumnsByPositionAndStationId: (
    state: StationFileStateType,
  ) => (id: string, type: 'primary' | 'alternative') => Column[]
  getColumnByStationIdAndSensorId: (
    state: StationFileStateType,
  ) => (stationId: string, sensorId: string) => Column | undefined
}
export const StationFileGetters = {
  getFilenameStationFileById: (state: StationFileStateType) => (id: string) => {
    const stationFile = state.stationFiles.find(stationFile => stationFile.id === id)
    return stationFile?.filename
  },
  getStationFileByStationId: (state: StationFileStateType) => (id: string) => {
    return state.stationFiles.find(stationFile => stationFile.stationId === id)
  },
  getStationFileIdByStationId: (state: StationFileStateType) => (id: string) => {
    const stationFile = state.stationFiles.find(stationFile => stationFile.stationId === id)
    return stationFile?.id || ''
  },
  getOrderedColumnsByPositionAndStationId:
    (state: StationFileStateType) => (id: string, type: string) => {
      const stationFile = state.stationFiles.find(stationFile => stationFile.stationId === id)
      if (!stationFile) return []
      const positionKey = type === 'primary' ? 'positionView' : 'positionViewAlternative'

      return stationFile.columns
        .filter(column => column[positionKey] >= 0) // ignorar columnas con -1
        .sort((a, b) => a[positionKey] - b[positionKey]) // ordenar de menor a mayor
    },
  getColumnByStationIdAndSensorId:
    (state: StationFileStateType) => (stationId: string, sensorId: string) => {
      const stationFile = state.stationFiles.find(
        stationFile => stationFile.stationId === stationId,
      )
      if (!stationFile) return undefined
      return stationFile.columns.find(column => column.sensorId === sensorId)
    },
}
