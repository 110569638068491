import { ChartSetting } from '../models/chartSetting'
export interface ChartSettingStateType {
  chartSettings: ChartSetting[]
  chartSetting: ChartSetting | null
}

const ChartSettingState: () => ChartSettingStateType = () => ({
  chartSettings: [],
  chartSetting: null,
})

export default ChartSettingState
