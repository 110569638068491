import { defineStore } from 'pinia'
import ChartSettingState, { ChartSettingStateType } from './state'
import { ChartSettingActions, ChartSettingActionsType } from './actions'
import { ChartSettingGetters } from './getters'

export const useChartSettingStore = defineStore<
  'chartSetting',
  ChartSettingStateType,
  typeof ChartSettingGetters,
  ChartSettingActionsType
>('chartSetting', {
  state: ChartSettingState,
  actions: ChartSettingActions,
  getters: ChartSettingGetters,
})
