import { LocationStateType } from './state'

export interface LocationGettersType {
  getNameLocationById: (state: LocationStateType) => (id: string) => LocationStateType['location']
}

export const LocationGetters = {
  getNameLocationById: (state: LocationStateType) => (id: string) => {
    const location = state.locations.find(location => location.id === id)
    return location?.name
  },
}
