export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime() //Timestamp
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}
export function setChileanDateToString(firedate) {
  const timestamp = new Date(firedate.seconds * 1000 + firedate.nanoseconds / 1000000)
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ]

  const day = timestamp.getDate()
  const month = months[timestamp.getMonth()]
  const year = timestamp.getFullYear()

  const hour = String(timestamp.getHours()).padStart(2, '0')
  const minutes = String(timestamp.getMinutes()).padStart(2, '0')

  const setDate = `${day} de ${month} de ${year} a las ${hour}:${minutes} hrs`

  return setDate
}

export function setChileanDateToStringByISO(isoDate) {
  const timestamp = new Date(isoDate)
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ]

  const day = timestamp.getDate()
  const month = months[timestamp.getMonth()]
  const year = timestamp.getFullYear()

  const setDate = `${day} de ${month} de ${year} `

  return setDate
}

export function setChileanDatetimeToStringByISO(isoDate) {
  if (!isoDate) return
  const timestamp = new Date(
    isoDate.substring(0, 4) +
      '-' +
      isoDate.substring(5, 7) +
      '-' +
      isoDate.substring(8, 10) +
      'T' +
      isoDate.substring(11, 13) +
      ':' +
      isoDate.substring(14, 16) +
      ':' +
      isoDate.substring(17, 19),
  )
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ]

  const day = timestamp.getDate()
  const month = months[timestamp.getMonth()]
  const year = timestamp.getFullYear()

  const hour = String(timestamp.getHours()).padStart(2, '0')
  const minutes = String(timestamp.getMinutes()).padStart(2, '0')

  const setDate = `${day} de ${month} de ${year} a las ${hour}:${minutes} hrs`

  return setDate
}
export function setShortChileanDatetimeToStringByISO(isoDate) {
  if (!isoDate) return
  const timestamp = new Date(
    isoDate.substring(0, 4) +
      '-' +
      isoDate.substring(5, 7) +
      '-' +
      isoDate.substring(8, 10) +
      'T' +
      isoDate.substring(11, 13) +
      ':' +
      isoDate.substring(14, 16) +
      ':' +
      isoDate.substring(17, 19),
  )
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ]

  const day = timestamp.getDate()
  const month = months[timestamp.getMonth()]
  const year = timestamp.getFullYear()

  const hour = String(timestamp.getHours()).padStart(2, '0')
  const minutes = String(timestamp.getMinutes()).padStart(2, '0')

  const setDate = `${day}, ${month.substring(0, 3)} ${year.toString().substring(2, 4)}, ${hour}:${minutes} hrs`

  return setDate
}
