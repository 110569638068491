// stores/theme.ts
import { defineStore } from 'pinia'

interface ThemeState {
  currentTheme: string
}

export const useThemeStore = defineStore('theme', {
  state: (): ThemeState => ({
    currentTheme: 'myCustomLightTheme',
  }),

  actions: {
    toggleTheme(): void {
      this.currentTheme =
        this.currentTheme === 'myCustomLightTheme' ? 'myCustomDarkTheme' : 'myCustomLightTheme'
    },
    setTheme(theme: string): void {
      this.currentTheme = theme
    },
  },
})
