import baseUnits from '@/views/Sensor/baseUnits.json'

interface ConversionUnit {
  key: string
  unit: string
  name: string
  slug: string
  decimals: number
  normalizationRatio: number
  normalizationOffset: number
}

interface BaseUnitValues {
  base: string
  conversions: ConversionUnit[]
}

interface BaseUnit {
  type: string
  name: string
  values: BaseUnitValues
}

export function convertUnit(
  value: number,
  unitMeasureNormalized: string,
  unitMeasureView: string,
  unitType: string,
): number {
  // Si las unidades son iguales, retornar el mismo valor
  if (unitMeasureNormalized === unitMeasureView) {
    return value
  }

  const unitConfig = baseUnits.find((unit: BaseUnit) => unit.type === unitType)

  if (!unitConfig) {
    throw new Error(`Unit type ${unitType} not found`)
  }

  // ordenar las conversiones por normalizationRatio
  const orderedConversions = [...unitConfig.values.conversions].sort(
    (a, b) => a.normalizationRatio - b.normalizationRatio,
  )

  const fromUnit = orderedConversions.find(
    (conv: ConversionUnit) => conv.key === unitMeasureNormalized,
  )
  const toUnit = orderedConversions.find((conv: ConversionUnit) => conv.key === unitMeasureView)

  if (!fromUnit || !toUnit) {
    throw new Error('conversion invalida')
  }

  const fromIndex = orderedConversions.indexOf(fromUnit)
  const toIndex = orderedConversions.indexOf(toUnit)

  // se debe dividir
  if (toIndex > fromIndex) {
    return Number(
      (value / (toUnit.normalizationRatio / fromUnit.normalizationRatio)).toFixed(toUnit.decimals),
    )
  } else {
    // si es de una unidad mayor
    return Number(
      (value * (fromUnit.normalizationRatio / toUnit.normalizationRatio)).toFixed(toUnit.decimals),
    )
  }
}
export function convertAlarmUnit(
  value: number,
  unitMeasureNormalized: string, // unidad de origen (la que viene en el valor)
  unitMeasureView: string, // unidad a la que se quiere convertir
  unitType: string,
): number {
  if (unitMeasureNormalized === unitMeasureView) {
    return value
  }
  const unitConfig = baseUnits.find((unit: BaseUnit) => unit.type === unitType)
  if (!unitConfig) {
    throw new Error(`Unit type ${unitType} not found`)
  }

  const fromUnit = unitConfig.values.conversions.find(
    (conv: ConversionUnit) => conv.key === unitMeasureNormalized,
  )
  const toUnit = unitConfig.values.conversions.find(
    (conv: ConversionUnit) => conv.key === unitMeasureView,
  )

  if (!fromUnit || !toUnit) {
    throw new Error('Conversión inválida')
  }

  const result = (value * fromUnit.normalizationRatio) / toUnit.normalizationRatio

  return Number(result.toFixed(toUnit.decimals))
}
