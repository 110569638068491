import { Alarm } from '../models/alarm'
export interface AlarmStateType {
  alarms: Alarm[]
  alarm: Alarm | null
}

const AlarmState: () => AlarmStateType = () => ({
  alarms: [],
  alarm: null,
})

export default AlarmState
