import { db, auth } from '@/config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { signInWithEmailAndPassword } from 'firebase/auth'
import router from '@/router'
import { AuthStateType } from './authState'

interface UserLogin {
  uid: string
  email: string
  emailVerified: boolean
}

interface User {
  id: string
  name: string
  email: string
}

interface UserProfile {
  id?: string | null
  name: string
  role: string
  email: string
  uid: string
  type?: string
  companyId?: string
}

interface AuthError {
  code: string
}

export interface AuthActionsType {
  signIn(user: { email: string; password: string }): Promise<void>
  detectUser(user: User | null): void
  logOut(): void
  updateOfflineStatus(status: boolean): void
  actionAddUserProfile(data: { userProfile: UserProfile }): void
}

function manageErrorLogin(error: AuthError): string {
  let status = ''
  switch (error.code) {
    case 'auth/user-not-found':
      status = 'El correo electrónico no está registrado. Por favor, regístrate.'
      break
    case 'auth/wrong-password':
      status = 'La contraseña es incorrecta. Verifica tus credenciales.'
      break
    case 'auth/too-many-requests':
      status = 'Su cuenta ha sido desactivada temporalmente.'
      break
    default:
      status =
        'Se ha producido un error al iniciar sesión. Por favor, inténtalo de nuevo más tarde.'
  }
  return status
}

async function getUserProfile(userId: string): Promise<UserProfile | undefined> {
  const docRef = doc(db, 'users', userId)
  const docSnap = await getDoc(docRef)
  let userProfile: UserProfile | undefined
  if (docSnap.exists()) {
    userProfile = docSnap.data() as UserProfile
  }
  return userProfile
}

export const AuthActions = {
  async signIn(this: AuthStateType, user: { email: string; password: string }) {
    try {
      const usuario = await signInWithEmailAndPassword(auth, user.email, user.password)

      const userLogin: UserLogin = {
        uid: usuario.user.uid,
        email: usuario.user.email ?? '',
        emailVerified: usuario.user.emailVerified,
      }

      this.user = {
        id: userLogin.uid,
        name: '',
        email: userLogin.email,
      }

      const profile = await getUserProfile(userLogin.uid)

      router.push('/')

      const newObjectJSON = JSON.stringify({
        name: profile?.name || '',
        role: profile?.role || '',
        email: userLogin.email,
        uid: userLogin.uid,
        companyId: profile?.companyId || '',
      })
      localStorage.setItem('user', newObjectJSON)

      this.userProfile = {
        id: profile?.id || '',
        name: profile?.name || '',
        role: profile?.role || '',
        email: userLogin.email,
        uid: userLogin.uid,
        companyId: profile?.companyId || '',
      }
    } catch (error) {
      const status = manageErrorLogin(error as AuthError)
      this.errorLoginMsg = status
    }
  },

  detectUser(this: AuthStateType, user: User | null) {
    this.user = user
  },

  logOut(this: AuthStateType) {
    auth.signOut().then(() => {
      router.push('/auth/login')
    })
  },

  updateOfflineStatus(this: AuthStateType, status: boolean) {
    this.isOffline = status
  },

  actionAddUserProfile(this: AuthStateType, data: { userProfile: UserProfile }) {
    this.userProfile = data.userProfile
  },
}
