import { defineStore } from 'pinia'
import StationState, { StationStateType } from './state'
import { StationActions, StationActionsType } from './actions'
import { StationGetters } from './getters'

export const useStationStore = defineStore<
  'station',
  StationStateType,
  typeof StationGetters,
  StationActionsType
>('station', {
  state: StationState,
  actions: StationActions,
  getters: StationGetters,
})
