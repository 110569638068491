// stores/theme.ts
import { defineStore } from 'pinia'

interface GlobalState {
  loadData: boolean
}

export const useGlobalStore = defineStore('global', {
  state: (): GlobalState => ({
    loadData: false,
  }),

  actions: {
    setLoadData(loadData: boolean): void {
      this.loadData = loadData
    },
  },
})
