<script setup lang="ts">
import { ref, computed, onMounted, watch, provide, defineExpose } from 'vue'
import { useAuthStore } from '@/views/Authentication/store'
import { useThemeStore } from '@/stores/theme'
import { useDisplay } from 'vuetify'

//import stores
import { useStationFileStore } from '@/views/Station/StationFile/store'
import { useSensorStore } from '@/views/Sensor/store'
import { useStationStore } from '@/views/Station/store'
import { useLocationStore } from '@/views/Location/store'
import { useAlarmStore } from '@/views/Alarm/store'
import router from '@/router'

import { useSensorGroupStore } from '@/views/Sensor/SensorGroup/store'
import { useChartSettingStore } from '@/views/ChartSetting/store'
import { useUserStationPreferenceStore } from '@/views/Station/UserStationPreference/store'
import { useGlobalStore } from '@/stores/global'
import { useUserAppPreferenceStore } from '@/views/User/UserAppPreference/store'
//store
const authStore = useAuthStore()
const themeStore = useThemeStore()
const stationFileStore = useStationFileStore()
const sensorStore = useSensorStore()
const stationStore = useStationStore()
const locationStore = useLocationStore()
const alarmStore = useAlarmStore()
const sensorGroupStore = useSensorGroupStore()
const chartSettingStore = useChartSettingStore()
const userStationPreferencesStore = useUserStationPreferenceStore()
const globalStore = useGlobalStore()
const userAppPreferenceStore = useUserAppPreferenceStore()

// const mini = ref<boolean>(false);
// const selectedItem = ref<number>(0);
const isMobileLocal = ref<boolean>(false)
const menu = ref<boolean>(false)
const open = ref<string[]>([])
const { mobile } = useDisplay()

//search
const searchQuery = ref<string>('');
const menuSearch = ref<boolean>(false);

const menuViews = ref([

]);

const stations = ref([

]);

const alarms = ref([

]);

const filteredResults = ref({
  menuViews: [] as { name: string; link: string }[],
  stations: [] as { name: string; link: string }[],
  alarms: [] as { name: string; link: string }[]
});

const hasResults = computed(() =>
  filteredResults.value.menuViews.length > 0 ||
  filteredResults.value.stations.length > 0 ||
  filteredResults.value.alarms.length > 0
);

function filterResults() {
  const query = searchQuery.value.toLowerCase();

  filteredResults.value.menuViews = menuViews.value.filter((view) =>
    view.name.toLowerCase().includes(query)
  );
  filteredResults.value.stations = stations.value.filter((station) =>
    station.name.toLowerCase().includes(query)
  );
  filteredResults.value.alarms = alarms.value.filter((alarm) =>
    alarm.name.toLowerCase().includes(query)
  );

  menuSearch.value = hasResults.value;
}

function navigate(link: string) {
  // Redirigir a la ruta usando Vue Router
  menuSearch.value = false;
  router.push(link);
}


type Item = {
  title: string
  value: string
  icon: string
  items?: Item[]
}

const drawer = ref<boolean>(true)
//const group = ref<string | null>(null);
const items = ref<Item[]>([])

//computed

const currentTheme = computed(() => themeStore.currentTheme)
const userProfile = computed(() => authStore.userProfile)
const loadData = computed(() => globalStore.loadData)
const locationStations = computed(() => locationStore.locations.filter(location => location.typeUse === 'station'))
const alarmsConfigured = computed(() => alarmStore.alarms)
// Métodos
const toggleDrawer = () => {
  drawer.value = !drawer.value
}

const hideDrawer = () => {
  drawer.value = false
}

defineExpose({ hideDrawer });

// Provees el método para que los componentes hijos lo puedan inyectar
provide('hideDrawer', hideDrawer);

function btnToggleTheme() {
  themeStore.toggleTheme()
}

const checkMobile = () => {
  isMobileLocal.value = window.innerWidth <= 500
}

const btnLogOut = () => {
  authStore.logOut()
}

const createConditionMenuByRole = () => {
  if (userProfile.value.role == 'admin') {
    items.value = [
      { title: 'Inicio', value: '/', icon: 'mdi-home', items: [] },
      {
        title: 'Dga',
        value: '/root',
        icon: 'mdi-domain',
        items: [
          { title: 'Dga Logs', value: '/dga-logs', icon: 'mdi-text-box', items: [] },
          {
            title: 'Dga credenciales',
            value: '/dga-credentials',
            icon: 'mdi-text-box',
            items: [],
          },
        ],
      },
      { title: 'Localizaciones', value: '/locations', icon: 'mdi-google-maps', items: [] },
      {
        title: 'Alarmas',
        value: 'root',
        icon: 'mdi-alert',
        items: [
          { title: 'Alarmas', value: '/alarms', icon: 'mdi-alarm', items: [] },
          { title: 'Alarmas Cliente', value: '/alarms-customer', icon: 'mdi-alarm', items: [] },
          {
            title: 'Archivos de alarma',
            value: '/alarm-report-files',
            icon: 'mdi-alarm',
            items: [],
          },
          { title: 'Log de alarmas', value: '/alarm-logs', icon: 'mdi-alarm', items: [] },
        ],
      },
      { title: 'Clientes', value: '/companies', icon: 'mdi-account', items: [] },
      {
        title: 'Panel Monitoreo',
        value: '/monitoring-panel',
        icon: 'mdi-view-dashboard-outline',
        items: [],
      },
      //estaciones
      {
        title: 'Estaciones',
        value: 'root',
        icon: 'mdi-ruler',
        items: [
          { title: 'Archivo estaciones', value: '/station-files', icon: 'mdi-plus', items: [] },
          { title: 'Estaciones', value: '/stations', icon: 'mdi-plus' },
          { title: 'Estaciones Cliente', value: '/stations-customer', icon: 'mdi-plus' },
          {
            title: 'Sincronización estaciones',
            value: '/load-station-files',
            icon: 'mdi-plus',
            items: [],
          },
          { title: 'Sensores', value: '/sensors', icon: 'mdi-plus', items: [] },
          { title: 'Grupos de sensor', value: '/sensor-groups', icon: 'mdi-plus', items: [] },
          { title: 'Ajustes de graficos', value: '/chart-settings', icon: 'mdi-plus', items: [] },
          //{ title: 'Normalizacion de sensores', value: '/sensor-normalizations', icon: 'mdi-plus', items: [] },
          {
            title: 'Curvas de descarga',
            value: '/station-discharge-curve',
            icon: 'mdi-plus',
            items: [],
          },
          {
            title: 'Importación ',
            value: '/measurement-imports',
            icon: 'mdi-file-upload',
            items: [],
          },
        ],
      },
      {
        title: 'Técnica',
        value: '/',
        icon: 'mdi-signal',
        items: [
          { title: 'Equipos', value: '/equips', icon: 'mdi-plus', items: [] },
          {
            title: 'Métodos de medición',
            value: '/measurement-methods',
            icon: 'mdi-plus',
            items: [],
          },
          {
            title: 'Instrumento de medicion',
            value: '/width-methods',
            icon: 'mdi-plus',
            items: [],
          },
        ],
      },
      //geografia
      {
        title: 'Geografia',
        value: 'root',
        icon: 'mdi-earth',
        items: [
          { title: 'Fuentes de agua', value: '/watersheds', icon: 'mdi-plus', items: [] },
          { title: 'Origenes de agua', value: '/water-sources', icon: 'mdi-plus', items: [] },
          { title: 'Sistemas de agua', value: '/water-systems', icon: 'mdi-plus', items: [] },
        ],
      },
      { title: 'Mediciones', value: '/measurements', icon: 'mdi-tape-measure', items: [] },
      { title: 'Aforos', value: '/measurement-visits', icon: 'mdi-plus', items: [] },
      {
        title: 'Administracion',
        value: 'root',
        icon: 'mdi-shield',
        items: [
          {
            title: 'Equipo',
            value: '/root',
            icon: 'mdi-plus',
            items: [{ title: 'Colaboradores', value: '/users', icon: 'mdi-plus', items: [] }],
          },
          {
            title: 'Negocio',
            value: '/root',
            icon: 'mdi-plus',
            items: [{ title: 'Cotizaciones', value: '/quotes', icon: 'mdi-plus', items: [] }, { title: 'Clientes', value: '/customers', icon: 'mdi-plus', items: [] }],
          },
        ],
      },
      {
        title: 'Estado',
        value: '/root',
        icon: 'mdi-list-status',
        items: [
          { title: 'Log de conexiones', value: '/network-logs', icon: 'mdi-alarm', items: [] },
        ],
      },
    ]
  }
  if (userProfile.value.role == 'user') {
    items.value = [
      { title: 'Inicio', value: '/', icon: 'mdi-home', items: [] },
      {
        title: 'Panel Monitoreo',
        value: '/monitoring-panel',
        icon: 'mdi-view-dashboard-outline',
        items: [],
      },
      // {
      //   title: 'Localizaciones',
      //   value: '/locations-customer',
      //   icon: 'mdi-google-maps',
      //   items: [],
      // },
      { title: 'Estaciones', value: '/stations-customer', icon: 'mdi-antenna', items: [] },
      //      { title: 'Aforos', value: '/measurement-visits', icon: 'mdi-map', items: [] },

      {
        title: 'Alarmas',
        value: 'root',
        icon: 'mdi-alert',
        items: [
          { title: 'Alarmas', value: '/alarms-customer', icon: 'mdi-alarm', items: [] },
          { title: 'Log de alarmas', value: '/alarm-logs', icon: 'mdi-alarm', items: [] },
        ],
      },
      { title: 'DGA', value: '/dga-customer', icon: 'mdi-domain', items: [] },
      //geografia

      { title: 'Mi cuenta', value: '/user', icon: 'mdi-account', items: [] },
    ]
  }
  menuViews.value = items.value.reduce((acc, item) => {

    if (!item.items?.length) {
      acc.push({
        name: item.title,
        link: item.value,
      });
    }

    if (item.items && item.items.length > 0) {
      item.items.forEach(subItem => {
        acc.push({
          name: subItem.title,
          link: subItem.value,
        });
      });
    }
    return acc;
  }, []);
}

// cargar data

const loadDataByRole = async () => {
  // cargas defecto
  sensorStore.getSensors()
  sensorGroupStore.getSensorGroups()
  chartSettingStore.getChartSettings()
  if (userProfile.value.role == 'admin') {
    await stationFileStore.getStationFiles()
    await stationStore.getStations()
    await alarmStore.getAlarms()
    await locationStore.getLocations()
  }
  if (userProfile.value.role == 'user') {
    //cargar data
    await locationStore.getLocationsByCompanyId(userProfile.value?.companyId || '')
    let locationIds = locationStore.locations
      .filter(location => location.typeUse === 'station')
      .reduce((acc, location) => {
        acc.push(location.id)
        return acc
      }, [] as string[])
    //cargar estaciones
    await stationStore.getStationsByLocationIds(locationIds)
    let stationIds = stationStore.stations.reduce((acc, station) => {
      acc.push(station.id)
      return acc
    }, [] as string[])
    //cargar archivos de estaciones
    await stationFileStore.getStationFilesByStationIds(stationIds)
    await alarmStore.getAlarmsByUserId(userProfile.value?.id || '')
    await userStationPreferencesStore.getUserStationPreferences(userProfile.value?.id || '')

  }
  await userAppPreferenceStore.getUserAppPreferences(userProfile.value?.id || '')
  globalStore.setLoadData(true)
}


//watch para cuando cambie el profile
watch(userProfile, () => {
  createConditionMenuByRole()
})

watch(locationStations, (newLocationStations) => {

  stations.value = newLocationStations.map(station => ({
    name: station.name,
    link: `/stations/${station.id}`,
  }));
}, { immediate: true });

watch(alarmsConfigured.value, (newAlarms) => {

  alarms.value = newAlarms.map(alarm => ({
    name: alarm.name,
    link: `/alarm/${alarm.id}`,
  }));

}, { immediate: true });


// Lifecycle Hooks
onMounted(() => {
  window.addEventListener('resize', checkMobile)
  createConditionMenuByRole()

  checkMobile()
  loadDataByRole()
  //si es mobile oculta el drawer del menu
  if (isMobileLocal.value) {
    drawer.value = false
  }
})
</script>
<template>
  <v-card class="bg-background" elevation="0">


    <v-navigation-drawer class="bg-background drawer" v-model="drawer" app
      :location="$vuetify.display.mobile ? 'bottom' : undefined" floating permanent>
      <v-card class="bg-background" elevation="0">
        <v-card-text>
          <div class="mx-auto my-4 text-center">
            <v-img src="aqfletter.svg"></v-img>


            <v-divider class="my-5 "></v-divider>

            <v-app-bar-nav-icon v-if="mobile" class="mx-2" variant="text"
              @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          </div>
        </v-card-text>
      </v-card>
      <d-divider />
      <v-list v-model:opened="open">
        <template v-for="(menu, i) in items" :key="i">
          <v-list-group v-if="menu.items && menu.items.length > 0" :value="menu.title">
            <template v-slot:activator="{ props }">
              <v-list-item class="my-2 ml-2" v-bind="props" :prepend-icon="menu.icon" :title="menu.title"
                rounded="xl"></v-list-item>
            </template>

            <template v-for="(subMenu, j) in menu.items" :key="`sub-${i}-${j}`">
              <v-list-group v-if="subMenu.items && subMenu.items.length > 0" :value="subMenu.title">
                <template v-slot:activator="{ props }">
                  <v-list-item class="my-2 ml-2" v-bind="props" :title="subMenu.title" rounded="xl"></v-list-item>
                </template>

                <v-list-item v-for="(nestedItem, k) in subMenu.items" :key="`nested-${i}-${j}-${k}`"
                  :title="nestedItem.title" :to="nestedItem.value"></v-list-item>
              </v-list-group>

              <v-list-item v-else :title="subMenu.title" :to="subMenu.value" class="my-2 ml-2" rounded="xl"
                @click="isMobileLocal ? drawer = false : true"></v-list-item>
            </template>
          </v-list-group>

          <v-list-item v-else :prepend-icon="menu.icon" :title="menu.title" :to="menu.value"
            @click="isMobileLocal ? drawer = false : true" class="my-2 ml-2" rounded="xl"></v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block>
            Cerrar sesión
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar class="bg-background pt-3 " elevation="0">
      <v-app-bar-nav-icon class="mx-2" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="btn-search ml-4">

        <v-menu v-model="menuSearch" :close-on-content-click="false" max-width="400">
          <template v-slot:activator="{ props }">

            <v-text-field v-model="searchQuery" v-bind="props" class="mt-5 mr-2" placeholder="Buscar" variant="outlined"
              append-inner-icon="mdi-magnify" density="compact" @click="filterResults"
              @input="filterResults"></v-text-field>
          </template>
          <template v-slot:default="menu">
            <v-card>
              <v-card-text>
                <template v-if="hasResults">
                  <v-list>
                    <v-subheader>Vistas del menú</v-subheader>
                    <v-list-item v-for="(item, index) in filteredResults.menuViews" :key="'menuView-' + index"
                      @click="navigate(item.link)">
                      {{ item.name }}
                    </v-list-item>
                  </v-list>
                  <v-divider v-if="filteredResults.stations.length"></v-divider>

                  <v-list v-if="filteredResults.stations.length">
                    <v-subheader>Estaciones</v-subheader>
                    <v-list-item v-for="(station, index) in filteredResults.stations" :key="'station-' + index"
                      @click="navigate(station.link)">
                      {{ station.name }}
                    </v-list-item>
                  </v-list>
                  <v-divider v-if="filteredResults.alarms.length"></v-divider>

                  <v-list v-if="filteredResults.alarms.length">
                    <v-subheader>Alarmas</v-subheader>
                    <v-list-item v-for="(alarm, index) in filteredResults.alarms" :key="'alarm-' + index"
                      @click="navigate(alarm.link)">
                      {{ alarm.name }}
                    </v-list-item>
                  </v-list>
                </template>
                <template v-else>
                  <v-list-item>No se encontraron resultados</v-list-item>
                </template>
              </v-card-text>
            </v-card>
          </template>
        </v-menu>
      </div>

      <v-spacer></v-spacer>
      <div class="d-flex mt-1">
        <v-btn v-if="currentTheme == 'myCustomDarkTheme'" class="ma-1 bg-bg-primary  rounded-lg" tile color="white"
          icon="mdi-white-balance-sunny" @click="btnToggleTheme"></v-btn>
        <v-btn v-if="currentTheme == 'myCustomLightTheme'" class="ma-1 bg-indigo-lighten-5  rounded-lg" tile
          color="primary" icon="mdi-moon-waning-crescent" @click="btnToggleTheme"></v-btn>
        <!-- <v-btn class="ma-1 rounded-lg" tile
          :class="currentTheme == 'myCustomDarkTheme' ? 'bg-bg-primary ' : 'bg-indigo-lighten-5 primary'"
          :color="currentTheme == 'myCustomDarkTheme' ? 'white ' : 'primary'" icon="mdi-bell"></v-btn>
        <v-btn class="ma-1 rounded-lg"
          :class="currentTheme == 'myCustomDarkTheme' ? 'bg-bg-primary ' : 'bg-indigo-lighten-5 primary'" tile
          :color="currentTheme == 'myCustomDarkTheme' ? 'white ' : 'primary'" icon="mdi-help-circle"></v-btn> -->
      </div>
      <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
        <template v-slot:activator="{ props }">

          <div v-bind="props" class="mr-4 d-flex pa-1 px-3 pa-3 rounded-lg align-center ml-2 mt-1"
            :class="currentTheme == 'myCustomDarkTheme' ? 'bg-bg-primary ' : 'bg-indigo-lighten-5 primary'">
            <v-avatar size="28" color="primary">
              <span class="text-h5">{{ userProfile?.name.substring(0, 1) }}</span>
            </v-avatar>
            <v-icon class="mx-3">mdi-cog</v-icon>
          </div>
        </template>

        <v-card min-width="300" class="pa-3" elevation="4">
          <v-list>
            <!-- :prepend-avatar="userProfile?.name?.substring(0,1)" -->
            <v-list-item :subtitle="userProfile?.email" :title="userProfile?.name">
              <template v-slot:append>
                <v-btn class="mx-3" icon="mdi-account-circle" to="/user" variant="text"></v-btn>
              </template>
            </v-list-item>
          </v-list>
          <h3></h3>

          <v-divider></v-divider>

          <!-- <v-list>
            <v-list-item>
              <v-switch v-model="message" color="purple" label="Desactivar mensajes" hide-details></v-switch>
            </v-list-item>

            <v-list-item>
              <v-switch v-model="hints" color="purple" label="Silenciar alarmas" hide-details></v-switch>
            </v-list-item>
          </v-list> -->

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn variant="text" rounded @click="btnLogOut">
              Desconectar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!-- <template v-if="$vuetify.display.mdAndUp">
              <v-btn icon="mdi-magnify" variant="text"></v-btn>
            </template> -->

    </v-app-bar>

    <v-main>

      <v-container v-if="loadData">
        <div class=" bg-bg-primary card-rounded  mt-2 ">
          <slot></slot>

        </div>
        <div class="footer-text">
          Desarrollado por AquaFlow <span class="heart-icon">®</span> Todos los derechos reservados
        </div>
      </v-container>
      <template v-else>
        <div class="loading">
          <v-progress-circular size="60" class="mx-auto my-auto" indeterminate color="primary" />
        </div>
      </template>
    </v-main>

  </v-card>
</template>

<style>
.app {
  background-color: #ffff;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Estilos generales */
* {
  font-family: 'Ubuntu', sans-serif;
  font-size: 13px;

}

::-webkit-scrollbar {
  display: none;
}

/* Títulos */
h1 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 2px;
}



/* Subtítulos */
h2 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
}

/* Información adicional */
h3 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
}

/* Modificar rotación de ícono versión escritorio */
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

.card-rounded {
  border-radius: 20px;
}

.btn-search {
  width: 200px;
}

.footer-text {
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  color: #666666;
  text-align: center;
  padding: 10px;
  display: block;
  background-color: transparent;
}

.footer-text .heart-icon {
  color: #000;
  margin: 0 3px;
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 10;
  overflow-y: auto;

  /* Opcional para separador visual */
}
</style>
