import { Measurement } from '@/views/Measurement/models/measurement'

export class Station {
  id: string
  locationId: string
  name: string
  extendedName: string
  description: string
  slug: string
  initDate: Date
  active: boolean
  utm: string
  lat: number
  lng: number
  altitude: number
  periodicity: number
  alarmed: boolean
  images: Image[]
  demoStationId: string
  lastMeasurement: Measurement | null
  createdAt: Date
  updatedAt: Date

  private spanishNames?: Record<string, string> = {
    id: 'identificador',
    locationId: 'id ubicación',
    name: 'nombre',
    extendedName: 'nombre extendido',
    description: 'descripción',
    slug: 'slug',
    initDate: 'fecha de inicio',
    active: 'activo',
    utm: 'utm',
    lat: 'latitud',
    lng: 'longitud',
    altitude: 'altitud',
    periodicity: 'periodicidad',
    alarmed: 'alarmado',
    images: 'imágenes',
    demoStationId: 'id de estación demo',
    lastMeasurement: 'última medición',
    createdAt: 'creado en',
    updatedAt: 'actualizado en',
  }

  constructor(data?: Partial<Station>) {
    this.id = data?.id ?? ''
    this.locationId = data?.locationId ?? ''
    this.name = data?.name ?? ''
    this.extendedName = data?.extendedName ?? ''
    this.description = data?.description ?? ''
    this.slug = data?.slug ?? ''
    this.initDate = data?.initDate ? new Date(data.initDate) : new Date()
    this.active = data?.active ?? false
    this.utm = data?.utm ?? ''
    this.lat = data?.lat ?? 0
    this.lng = data?.lng ?? 0
    this.altitude = data?.altitude ?? 0
    this.periodicity = data?.periodicity ?? 0
    this.alarmed = data?.alarmed ?? false
    this.images = data?.images ?? []
    this.demoStationId = data?.demoStationId ?? ''
    this.lastMeasurement = data?.lastMeasurement ? new Measurement(data.lastMeasurement) : null
    this.createdAt = data?.createdAt ? new Date(data.createdAt) : new Date()
    this.updatedAt = data?.updatedAt ? new Date(data.updatedAt) : new Date()
  }

  getSpanishName(propertyName: string): string {
    if (this.spanishNames && this.spanishNames[propertyName]) {
      return this.spanishNames[propertyName]
    }
    return propertyName
  }
  toPlainObject() {
    return {
      id: this.id,
      locationId: this.locationId,
      name: this.name,
      extendedName: this.extendedName,
      description: this.description,
      slug: this.slug,
      initDate: this.initDate.toISOString(),
      active: this.active,
      utm: this.utm,
      lat: this.lat,
      lng: this.lng,
      altitude: this.altitude,
      periodicity: this.periodicity,
      alarmed: this.alarmed,
      images: this.images.map(image => image.toPlainObject()),
      demoStationId: this.demoStationId,
      lastMeasurement: this.lastMeasurement ? this.lastMeasurement.toPlainObject() : null,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    }
  }
}

export class Image {
  id: string
  name: string
  description: string
  src: string
  position: number
  height: number
  width: number
  createdAt: Date
  updatedAt: Date

  constructor(data?: Partial<Image>) {
    this.id = data?.id ?? ''
    this.name = data?.name ?? ''
    this.description = data?.description ?? ''
    this.src = data?.src ?? ''
    this.position = data?.position ?? 0
    this.height = data?.height ?? 0
    this.width = data?.width ?? 0
    this.createdAt = data?.createdAt ? new Date(data.createdAt) : new Date()
    this.updatedAt = data?.updatedAt ? new Date(data.updatedAt) : new Date()
  }
  private spanishNames?: Record<string, string> = {
    id: 'identificador',
    name: 'nombre',
    description: 'descripción',
    src: 'src',
    position: 'posición',
    height: 'altura',
    width: 'ancho',
    createdAt: 'creado en',
    updatedAt: 'actualizado en',
  }
  getSpanishName(propertyName: string): string {
    if (this.spanishNames && this.spanishNames[propertyName]) {
      return this.spanishNames[propertyName]
    }
    return propertyName
  }
  toPlainObject() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      src: this.src,
      position: this.position,
      height: this.height,
      width: this.width,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    }
  }
}

export class TeneviaMeasurement {
  id: string
  measurementsId: string
  date: Date | string
  cameraId: string
  imageName: string
  createdAt: Date
  updatedAt: Date

  constructor(data?: Partial<TeneviaMeasurement>) {
    this.id = data?.id ?? ''
    this.measurementsId = data?.measurementsId ?? ''
    this.date = data?.date ? new Date(data.date) : new Date()
    this.cameraId = data?.cameraId ?? ''
    this.imageName = data?.imageName ?? ''
    this.createdAt = data?.createdAt ? new Date(data.createdAt) : new Date()
    this.updatedAt = data?.updatedAt ? new Date(data.updatedAt) : new Date()
  }

  private spanishNames?: Record<string, string> = {
    id: 'identificador',
    measurementsId: 'id de mediciones',
    date: 'fecha',
    cameraId: 'id de cámara',
    imageName: 'nombre de imagen',
    createdAt: 'creado en',
    updatedAt: 'actualizado en',
  }
  getSpanishName(propertyName: string): string {
    if (this.spanishNames && this.spanishNames[propertyName]) {
      return this.spanishNames[propertyName]
    }
    return propertyName
  }
  toPlainObject() {
    return {
      id: this.id,
      measurementsId: this.measurementsId,
      date: this.date instanceof Date ? this.date.toISOString() : this.date,
      cameraId: this.cameraId,
      imageName: this.imageName,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    }
  }
}
