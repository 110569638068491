import { convertDatesToISO } from '@/config/aux'
import { UserStationPreference } from '../models/userStationPreference'
import { UserStationPreferenceStateType } from './state'

import axios from 'axios'

export interface UserStationPreferenceActionsType {
  getUserStationPreferences(userId: string): Promise<void>
  getUserStationPreferenceByStationFileId(
    stationFileId: string,
    userId: string,
  ): Promise<UserStationPreference | null>
  postUserStationPreference(
    userStationPreference: UserStationPreference,
    userId: string,
  ): Promise<void>
  putUserStationPreference(
    userStationPreference: UserStationPreference,
    userId: string,
  ): Promise<void>
  deleteUserStationPreference(id: string, userId: string): Promise<void>
}

export const UserStationPreferenceActions = {
  async getUserStationPreferences(this: UserStationPreferenceStateType, userId: string) {
    try {
      this.userStationPreferences = []
      const query = `https://aqfcore-api.aquaflow.cl/user/${userId}/stationpreferences`
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((userStationPreference: UserStationPreference) => {
            this.userStationPreferences.push(userStationPreference)
          })
        }
      })
    } catch (error) {
      console.error('Error getting UserStationPreferences', error)
    }
  },
  async getUserStationPreferenceByStationFileId(
    this: UserStationPreferenceStateType,
    stationFileId: string,
    userId: string,
  ): Promise<UserStationPreference | null> {
    try {
      let userStationPreference: UserStationPreference | undefined
      const query = `https://aqfcore-api.aquaflow.cl/user/${userId}/stationpreferences/${stationFileId}`
      await axios.get(query).then(response => {
        if (response.status === 200) {
          userStationPreference = response.data as UserStationPreference
          this.userStationPreference = response.data as UserStationPreference
        }
      })
      return userStationPreference || null
    } catch (error) {
      console.error('Error getting UserStationPreferences', error)
      return null
    }
  },
  async postUserStationPreference(
    this: UserStationPreferenceStateType,
    userStationPreference: UserStationPreference,
    userId: string,
  ) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/user/${userId}/stationpreferences`
      const data = convertDatesToISO(userStationPreference.toPlainObject())
      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.userStationPreferences.push(userStationPreference)
        }
      })
    } catch (error) {
      console.error('Error posting UserStationPreference', error)
    }
  },
  async putUserStationPreference(
    this: UserStationPreferenceStateType,
    userStationPreference: UserStationPreference,
    userId: string,
  ) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/user/${userId}/stationpreferences`
      const data = convertDatesToISO(userStationPreference)
      //const dataJson = JSON.stringify(data)

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.userStationPreferences = this.userStationPreferences.map(a => (a.id === userStationPreference.id ? userStationPreference : a))
          }
        })
    } catch (error) {
      console.error('Error updating UserStationPreference', error)
    }
  },
  async deleteUserStationPreference(
    this: UserStationPreferenceStateType,
    id: string,
    userId: string,
  ) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/user/${userId}/stationpreferences/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.userStationPreferences = this.userStationPreferences.filter(a => a.id !== userStationPreference.id)
        }
      })
    } catch (error) {
      console.error('Error deleting UserStationPreference', error)
    }
  },
}
