import { useAuthStore } from '@/views/Authentication/store'
import { useMeasurementStore } from '@/views/Measurement/store'

export class WebSocketService {
  private socket: WebSocket | null = null
  private uid: string
  private companyId: string
  private authStore: ReturnType<typeof useAuthStore> | null = null
  private measurementStore: ReturnType<typeof useMeasurementStore> | null = null
  constructor(uid: string, companyId: string) {
    this.uid = uid
    this.companyId = companyId
  }

  setAuthStore(store: ReturnType<typeof useAuthStore>): void {
    this.authStore = store
  }
  setMeasurementStore(store: ReturnType<typeof useMeasurementStore>): void {
    this.measurementStore = store
  }

  connect(): void {
    if (!this.authStore) {
      console.error('AuahtStore is not inicialized')
      return
    }

    this.socket = new WebSocket(
      `wss://aqfcore-api.aquaflow.cl/websocket/${this.companyId}/${this.uid}`,
    )

    this.socket.onopen = () => {
      console.log('WebSocket connection established.')
    }

    this.socket.onmessage = (event: MessageEvent) => {
      this.measurementStore?.addMeasurement(event.data)
    }

    this.socket.onerror = (error: Event) => {
      console.error('WebSocket error:', error)
    }

    this.socket.onclose = () => {
      console.warn('WebSocket closed. Attempting to reconnect...')
      setTimeout(() => this.connect(), 5000)
    }
  }

  sendMessage(message: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message)
    } else {
      console.warn('WebSocket is not connected.')
    }
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.close()
      this.socket = null
    }
  }
}
