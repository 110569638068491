import { convertDatesToISO } from '@/config/aux'
import { Station, Image, TeneviaMeasurement } from '../models/station'
import { StationStateType } from './state'
import axios from 'axios'
import { fileInputFileStorage } from '@/utils/uploadFileStore'
export interface StationActionsType {
  getStations(): Promise<void>
  // getStationById(id: string): Promise<void>
  getStationsByLocationId(locationId: string): Promise<Station[]>
  getStationsByLocationIds(locationIds: string[]): Promise<void>
  getImagesTeneviaMeasurementsByStationIdAndDateRange(
    stationId: string,
    startDate: string,
    endDate: string,
    gtm: string,
  ): Promise<TeneviaMeasurement[]>
  postStation(station: Station, files: any): Promise<void>
  putStation(station: Station, files: any): Promise<void>
  deleteStation(id: string): Promise<void>
}

export const StationActions = {
  async getStations(this: StationStateType) {
    try {
      this.stations = []
      const query = 'https://aqfcore-api.aquaflow.cl/stations'
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((station: Station) => {
            this.stations.push(station)
          })
        }
      })
    } catch (error) {
      console.error('Error getting Stations', error)
    }
  },
  async getStationsByLocationId(this: StationStateType, locationId: string): Promise<Station[]> {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/stations?locationId=' + locationId
      const response = await axios.get(query)
      if (response.status === 200) {
        const stationsByLocation: Station[] = response.data

        return stationsByLocation as Station[]
      } else {
        return []
      }
    } catch (error) {
      console.error('Error getting Stations', error)
      return []
    }
  },
  async getStationsByLocationIds(this: StationStateType, locationIds: string[]): Promise<void> {
    try {
      this.stations = []
      const query = 'https://aqfcore-api.aquaflow.cl/stations/locationids'

      await axios.post(query, locationIds).then(response => {
        if (response.status === 200) {
          this.stations = response.data as Station[]
        }
      })
    } catch (error) {
      console.error('Error posting StationFile', error)
    }
  },
  // async getStationById(this: StationStateType, id: string): Promise<void> {
  //   try {
  //     const query = `https://aqfcore-api.aquaflow.cl/stations/${id}`
  //     const response = await axios.get(query)
  //     if (response.status === 200) {
  //       const station: Station = response.data
  //       this.station = station
  //     } else {
  //       this.station = null
  //     }
  //   } catch (error) {
  //     console.error('Error getting Station by Id', error)
  //     this.station = null
  //   }
  // },

  async getImagesTeneviaMeasurementsByStationIdAndDateRange(
    stationId: string,
    startDate: string,
    endDate: string,
    gtm: string,
  ): Promise<TeneviaMeasurement[]> {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/tenevia-measurements/station/${stationId}?startDate=${startDate}&endDate=${endDate}`
      const response = await axios.get(query)
      const gtmOffset = parseInt(gtm)
      if (response.status === 200) {
        let setMeasurements = response.data as TeneviaMeasurement[]
        setMeasurements = setMeasurements.map(measurement => {
          const date = new Date(measurement.date)
          date.setHours(date.getHours() + gtmOffset)
          measurement.date = date.toISOString()
          return measurement
        })
        return setMeasurements
      } else {
        return []
      }
    } catch (error) {
      console.error('Error getting Images Tenevia by StationId and Date Range', error)
      return []
    }
  },
  async postStation(this: StationStateType, station: Station, files: any) {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/stations'
      const images = new Array<Image>()
      // carar las imagenes
      if (files.lenght != 0) {
        for (const element of files.images) {
          const setImage = new Image(element.image)

          if (element.image.src == '') {
            const result = await fileInputFileStorage(element.file, '/stati/test', element.image.id)
            if (result != null) {
              setImage.src = result.url
            }
          }
          images.push(setImage)
        }
      }
      station.images = images
      const data = convertDatesToISO(station)
      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.stations.push(station)
        }
      })
    } catch (error) {
      console.error('Error posting Station', error)
    }
  },
  async putStation(this: StationStateType, station: Station, files: any) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/stations`

      const images = new Array<Image>()
      // carar las imagenes
      if (files.lenght != 0) {
        for (const element of files.images) {
          const setImage = new Image(element.image)

          if (element.image.src == '') {
            const result = await fileInputFileStorage(element.file, '/stati/test', element.image.id)
            if (result != null) {
              setImage.src = result.url
            }
          }
          images.push(setImage)
        }
      }
      station.images = images
      const data = convertDatesToISO(station)
      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.stations = this.stations.map(a => (a.id === station.id ? station : a))
          }
        })
    } catch (error) {
      console.error('Error updating Station', error)
    }
  },
  async deleteStation(this: StationStateType, id: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/stations/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.stations = this.stations.filter(a => a.id !== station.id)
        }
      })
    } catch (error) {
      console.error('Error deleting Station', error)
    }
  },
}
