<script setup lang="ts">
import StationCard from './Station/components/StationCard.vue'
import AlarmsCard from './Alarm/components/AlarmsCard.vue'
import StationCarousel from './Station/components/StationCarousel.vue'
import ActiveAlarmsCard from './Alarm/components/ActiveAlarmsCard.vue'
import MarkedStation from './Station/components/MarkedStation.vue'
import { useStationStore } from './Station/store'
import { computed, onMounted, ref, nextTick, onUnmounted } from 'vue'
import { useDisplay } from 'vuetify'
import { Station } from './Station/models/station'
import { Alarm } from './Alarm/models/alarm'
import { useAlarmStore } from './Alarm/store'
import { useLocationStore } from './Location/store'
import { useUserAppPreferenceStore } from './User/UserAppPreference/store'
import StationResumeChart from './Station/components/StationResumeChart.vue'
import WindyMap from '@/components/WindyMap.vue'

// store

const stationStore = useStationStore()
const alarmStore = useAlarmStore()
const locationStore = useLocationStore()
const userAppPreferenceStore = useUserAppPreferenceStore()
//data
const { mobile } = useDisplay()
const isPortrait = ref(false);
const selectedView = ref<string>('resume')

// computed
const stations = computed<Station[]>(() => stationStore.stations)
const alarms = computed<Alarm[]>(() => alarmStore.alarms)
const alarmsActive = computed<Alarm[]>(() => alarmStore.alarms.filter(alarm => alarm.active))
const userAppPreference = computed(() => userAppPreferenceStore.userAppPreference)
const markedStations = computed(() =>
  stations.value.filter(station =>
    userAppPreference.value?.markedStation?.stationIds?.includes(station.id)
  )
)
// methods
const checkOrientation = () => {
  isPortrait.value = window.matchMedia("(orientation: portrait)").matches;
};
// lifecycle


onMounted(() => {

  //verificar el perfil
  checkOrientation();
  window.addEventListener("resize", checkOrientation);
  window.matchMedia("(orientation: portrait)").addEventListener("change", checkOrientation);
})
onUnmounted(() => {
  window.removeEventListener("resize", checkOrientation);
  window.matchMedia("(orientation: portrait)").removeEventListener("change", checkOrientation);
});
</script>
<template>
  <v-card min-height="90vh" elevation="0" class="pa-5 rounded-lg">
    <div class="d-flex bg-background rounded-lg justify-space-between mb-4 pa-2">
      <h1 class="mt-2 ml-2">Inicio</h1>
      <v-btn-toggle v-model="selectedView" class="bg-background" borderless mandatory>
        <v-btn value="resume">
          <span class="hidden-sm-and-down">Resumen</span>

          <v-icon end>mdi-view-dashboard</v-icon>
        </v-btn>

        <v-btn value="stations">
          <span class="hidden-sm-and-down">Estaciones</span>

          <v-icon end>mdi-antenna</v-icon>
        </v-btn>
        <v-btn v-if="userAppPreference?.id" value="marked">
          <span class="hidden-sm-and-down">Marcadores</span>

          <v-icon end>mdi-pin</v-icon>
        </v-btn>
      </v-btn-toggle>
      <div>
        <!-- <v-btn>Ayuda</v-btn> -->
      </div>
    </div>
    <div>

      <v-row v-if="selectedView == 'resume'">
        <v-col cols="12" xs="12" sm="12" md="12" xl="6" lg="6" xxl="6">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" xl="6" lg="6" xxl="6">



              <StationCard :stations="stations" />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" xl="6" lg="6" xxl="6">
              <AlarmsCard :alarms="alarms" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <StationCarousel :stations="stations" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ActiveAlarmsCard :alarms="alarmsActive" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" xl="6" lg="6" xxl="6">
          <!-- <v-card
              class="card-rounded"
              height="76vh"
            >
              <iframe
                width="100%"
                height="100%"
                src="https://embed.windy.com/embed2.html?lat=-36.834&lon=-73.102&detailLat=-36.834&detailLon=-73.102&width=650&height=450&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
                frameborder="0"
              ></iframe>
            </v-card> -->
          <WindyMap :stations="stations" :height="'76vh'" :activeTooltip="true" />
        </v-col>
      </v-row>
      <v-row v-if="selectedView == 'stations'">
        <div v-if="isPortrait && mobile" class="overlay">
          <p>Para ver esta vista, use el teléfono en modo horizontal</p>
        </div>
        <StationResumeChart v-else :stations="stations" />
      </v-row>
      <v-row v-if="selectedView == 'marked'">

        <MarkedStation :stations="markedStations" />
      </v-row>


    </div>

  </v-card>
</template>
<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  z-index: 9999;
  padding: 20px;
}
</style>