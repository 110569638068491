import { StationStateType } from './state'

export interface StationGettersType {
  getStationById: (state: StationStateType) => (id: string) => StationStateType['station']
  getNameStationById: (state: StationStateType) => (id: string) => StationStateType['station']
}

export const StationGetters = {
  getStationById: (state: StationStateType) => (id: string) => {
    const station = state.stations?.find(station => station.id === id)
    return station
  },
  getNameStationById: (state: StationStateType) => (id: string) => {
    const station = state.stations?.find(station => station.id === id)
    return station?.name
  },
}
