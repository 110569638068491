<script lang="ts" setup>
import { defineProps, onMounted, ref } from 'vue'

import { Alarm } from '../models/alarm'
import { useStationStore } from '@/views/Station/store'
import { useSensorStore } from '@/views/Sensor/store'
import { setChileanDatetimeToStringByISO } from '@/config/aux'

import { getActivatedConditions } from '../utils'
import { getConversiontByTypeAndConvertionKey } from '@/views/Sensor/utils'
import { convertAlarmUnit } from '@/views/Measurement/normalizationMeasurement'
import { AlarmLog } from '../AlarmLog/models/alarmLog'
import { useAlarmLogStore } from '../AlarmLog/store'
import { useStationFileStore } from '@/views/Station/StationFile/store'
//props
const props = defineProps<{
  alarms: Alarm[]
}>()


//store
const stationStore = useStationStore()
const sensorStore = useSensorStore()
const alarmLogStore = useAlarmLogStore()
const stationFileStore = useStationFileStore()

//data
const alarmLogs = ref<AlarmLog[]>([])

const setAlarmIcon = (type: string) => {
  let icon = 'fa-ruler'
  switch (type) {
    case 'level':
      icon = 'mdi-ruler'
      break
    case 'warning':
      icon = 'mdi-exclamation'
      break
  }
  return icon
}

const searchLastAlarmLogByAlarmId = (alarmId: string) => {
  return alarmLogs.value.find(alarmLog => alarmLog.alarmId === alarmId)
}
const getAlarmsLogs = async () => {
  //recorrer las alarmas y traer el ultimo alarm logs de las que este activas
  alarmLogs.value = []
  const alarms = props.alarms.filter(alarm => alarm.active)
  for (const alarm of alarms) {
    let alarmLog
    let stationFile = await stationFileStore.getStationFileByStationId(alarm.stationId)
    if (stationFile) {

      alarmLog = await alarmLogStore.getLastAlarmLogByAlarmId(alarm.id, stationFile?.timestampGmt)
    }
    if (alarmLog) {
      alarmLogs.value.push(alarmLog)
    }
  }
}
onMounted(async () => {
  await getAlarmsLogs()
})

//lifecycle
</script>

<template>
  <v-card class="card-rounded bg-background mt-6">
    <div class="px-6 py-6">
      <h5>Alarmas activas</h5>
      <p class="text-sm text-body font-weight-light">
        Alarmas vigentes por revisar, se mantienen activas hasta desactivarlas. Estas pueden ser por
        error o creadas
      </p>
    </div>
    <div>
      <div class="px-6 pb-6">
        <div v-for="(alarm, i) in props.alarms" :key="alarm.id">
          <v-row class="d-flex ms-0">
            <v-col cols="1" class="text-center w-5">
              <v-icon class="mt-4 text-lg opacity-6 text-body">{{ setAlarmIcon('level') }} </v-icon>
            </v-col>
            <v-col class="my-auto ms-4">
              <div class="h-100">
                <p class="text-sm mb-1 text-body font-weight-light">
                  {{ stationStore.getNameStationById(alarm.stationId) }}
                </p>
                <p class="mt-2">
                  Condiciones activadas
                </p>
                <div v-if="searchLastAlarmLogByAlarmId(alarm.id)" class="d-flex">
                  <div
                    v-for="(activeCondition, index) in getActivatedConditions(alarm.groups[0].conditions, searchLastAlarmLogByAlarmId(alarm.id))"
                    :key="index">
                    <v-chip class="mr-2" label>{{
                      sensorStore.getNameSensorById(activeCondition.condition.sensorId) }} {{
                        activeCondition.condition.operation }} {{ activeCondition.condition.value }} {{
                        getConversiontByTypeAndConvertionKey(sensorStore.getSensorById(activeCondition.condition.sensorId)?.unitType
                          ??
                          '',
                          activeCondition.condition.unitMeasureOrigin ?? '')?.unit }} / Valor {{
                        convertAlarmUnit(
                          activeCondition.value,
                          sensorStore.getSensorById(activeCondition.condition.sensorId)?.unitMeasureNormalized ?? '',
                          activeCondition.condition.unitMeasureOrigin ?? '',
                          sensorStore.getSensorById(activeCondition.condition.sensorId)?.unitType ?? '',

                        ) }} {{
                        getConversiontByTypeAndConvertionKey(sensorStore.getSensorById(activeCondition.condition.sensorId)?.unitType
                          ??
                          '',
                          activeCondition.condition.unitMeasureOrigin ?? '')?.unit }} </v-chip>
                  </div>
                </div>
                <p v-if="searchLastAlarmLogByAlarmId(alarm.id)" class="mb-0 text-xs text-body font-weight-light">
                  Última activación {{ setChileanDatetimeToStringByISO(searchLastAlarmLogByAlarmId(alarm.id)?.timestamp)
                  }}
                </p>
              </div>
            </v-col>
            <v-col class="d-flex justify-end">

            </v-col>
          </v-row>
          <hr class="horizontal dark my-4" v-if="i != alarms?.length - 1" />
        </div>
      </div>
    </div>
  </v-card>
</template>
