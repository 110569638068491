import { SensorGroupStateType } from './state'

export interface SensorGroupGettersType {
  getNameSensorGroupById: (
    state: SensorGroupStateType,
  ) => (id: string) => SensorGroupStateType['sensorGroup']
}

export const SensorGroupGetters = {
  getNameSensorGroupById: (state: SensorGroupStateType) => (id: string) => {
    const sensorGroup = state.sensorGroups.find(sensorGroup => sensorGroup.id === id)
    return sensorGroup?.name
  },
}
