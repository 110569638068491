import { defineStore } from 'pinia'
import SensorGroupState, { SensorGroupStateType } from './state'
import { SensorGroupActions, SensorGroupActionsType } from './actions'
import { SensorGroupGetters } from './getters'

export const useSensorGroupStore = defineStore<
  'sensorGroup',
  SensorGroupStateType,
  typeof SensorGroupGetters,
  SensorGroupActionsType
>('sensorGroup', {
  state: SensorGroupState,
  actions: SensorGroupActions,
  getters: SensorGroupGetters,
})
