import { UserAppPreferenceStateType } from './state'

export interface UserAppPreferenceGettersType {
  getUserAppPreference: (
    state: UserAppPreferenceStateType,
  ) => UserAppPreferenceStateType['userAppPreference']
}

export const UserAppPreferenceGetters = {
  getUserAppPreference: (state: UserAppPreferenceStateType) => state.userAppPreference,
}
