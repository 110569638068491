import { Measurement } from '../Measurement/models/measurement'
import { Sensor } from '../Sensor/models/sensor'
import { Column } from './StationFile/models/stationFile'
import { convertUnit } from '../Measurement/normalizationMeasurement'
import { getConversiontByTypeAndConvertionKey } from '../Sensor/utils'
import { replaceDotByComma } from '@/config/aux'
export function getSensorIcon(name: string): string {
  const sensorIcons: Record<string, string> = {
    level: 'mdi-ruler',
    caudal: 'mdi-waves',
    battery: 'mdi-battery',
    waterVelocity: 'mdi-speedometer',
    rain: 'mdi-weather-pouring',
    temperature: 'mdi-thermometer',
    turbidity: 'mdi-water-alert',
    windSpeed: 'mdi-weather-windy-variant',
  }
  return sensorIcons[name] || 'mdi-help-circle'
}

export function getSensorValue(lastMeasurement: any, sensorName: string): any {
  if (sensorName === 'water_velocity') {
    return lastMeasurement['waterVelocity']
  }
  if (lastMeasurement && sensorName in lastMeasurement) {
    return lastMeasurement[sensorName]
  }
  return 'No data'
}

export function getUnitMeasureViewBySensorId(
  sensorId: string,
  columns: Column[],
  sensors: Sensor[],
): string {
  const columnPreference = columns.find(column => column.sensorId == sensorId)
  const sensor = sensors.find(sensor => sensor.id == sensorId)
  if (columnPreference?.unitMeasureView && sensor) {
    return (
      getConversiontByTypeAndConvertionKey(sensor?.unitType, columnPreference.unitMeasureView)
        ?.unit || columnPreference.unitMeasureView
    )
  } else {
    return 'No data'
  }
}

export function getSensorValueAndConvert(
  measurement: Measurement,
  sensor: Sensor,
  columns: Column[],
) {
  let value = getSensorValue(measurement, sensor?.field || '')

  const columnPreference = columns?.find(column => column.sensorId == sensor.id)
  if (columnPreference) {
    value = convertUnit(
      value,
      sensor.unitMeasureNormalized,
      columnPreference.unitMeasureView,
      sensor.unitType,
    )
  }

  return replaceDotByComma(value)
}

export function setGtmOffsetTimestamp(date: string, gtmOffset: string): string {
  const dateObj = new Date(date)
  dateObj.setHours(dateObj.getHours() + parseInt(gtmOffset))
  return dateObj.toISOString()
}
