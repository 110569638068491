export class Measurement {
  id: string
  rawMeasurementsId: string
  date: string | Date
  stationId: string
  //validated: boolean
  level: number
  caudal: number
  caudalAvg: number
  caudalTotal: number
  waterVelocity: number
  temperature: number
  rain: number
  humidity: number
  turbidity: number
  battery: number
  batterySolar: number
  batteryUps: number
  windSpeed: number
  windSpeedMax: number
  windDirection: number
  solarRadiation: number
  solarRadiationTotal: number
  pressure: number
  createdAt: Date
  updatedAt: Date
  createdBy: string
  updatedBy: string

  private spanishNames?: Record<string, string>

  constructor(data?: Partial<Measurement>) {
    this.id = data?.id ?? ''
    this.rawMeasurementsId = data?.rawMeasurementsId ?? ''
    this.date = data?.date ? new Date(data.date) : new Date()
    this.stationId = data?.stationId ?? ''
    //this.validated = data?.validated ?? false
    this.level = data?.level ?? 0
    this.caudal = data?.caudal ?? 0
    this.caudalAvg = data?.caudalAvg ?? 0
    this.caudalTotal = data?.caudalTotal ?? 0
    this.waterVelocity = data?.waterVelocity ?? 0
    this.temperature = data?.temperature ?? 0
    this.rain = data?.rain ?? 0
    this.humidity = data?.humidity ?? 0
    this.turbidity = data?.turbidity ?? 0
    this.battery = data?.battery ?? 0
    this.batterySolar = data?.batterySolar ?? 0
    this.batteryUps = data?.batteryUps ?? 0
    this.windSpeed = data?.windSpeed ?? 0
    this.windSpeedMax = data?.windSpeedMax ?? 0
    this.windDirection = data?.windDirection ?? 0

    this.solarRadiation = data?.solarRadiation ?? 0
    this.solarRadiationTotal = data?.solarRadiationTotal ?? 0
    this.pressure = data?.pressure ?? 0
    this.createdAt = data?.createdAt ? new Date(data.createdAt) : new Date()
    this.updatedAt = data?.updatedAt ? new Date(data.updatedAt) : new Date()
    this.createdBy = data?.createdBy ?? ''
    this.updatedBy = data?.updatedBy ?? ''

    this.spanishNames = {
      id: 'identificador',
      rawMeasurementId: 'identificador de la medición cruda',
      date: 'fecha',
      stationId: 'id de la estación',
      level: 'nivel',
      caudal: 'caudal',
      caudalAvg: 'caudal medio',
      caudalTotal: 'caudal total',
      waterVelocity: 'velocidad',
      temperature: 'temperatura',
      rain: 'lluvia',
      humidity: 'humedad',
      turbidity: 'turbidez',
      battery: 'estado batería',
      batterySolar: 'estado batería solar',
      batteryUps: 'estado batería ups',
      windSpeed: 'velocidad del viento',
      windSpeedMax: 'velocidad máxima del viento',
      windDirection: 'dirección del viento',
      solarRadiation: 'radiación solar',
      solarRadiationTotal: 'radiación solar total',
      pressure: 'presión',
      createdAt: 'fecha de creación',
      updatedAt: 'fecha de actualización',
      createdBy: 'creado por',
      updatedBy: 'actualizado por',
    }
  }

  getSpanishName(propertyName: string): string {
    if (this.spanishNames && this.spanishNames[propertyName]) {
      return this.spanishNames[propertyName]
    }
    return propertyName
  }
  toPlainObject() {
    return {
      id: this.id,
      rawMeasurementId: this.rawMeasurementsId,
      date: this.date,
      stationId: this.stationId,
      level: this.level,
      caudal: this.caudal,
      caudalAvg: this.caudalAvg,
      caudalTotal: this.caudalTotal,
      waterVelocity: this.waterVelocity,
      temperature: this.temperature,
      rain: this.rain,
      humidity: this.humidity,
      turbidity: this.turbidity,
      battery: this.battery,
      batterySolar: this.batterySolar,
      batteryUps: this.batteryUps,
      windSpeed: this.windSpeed,
      windSpeedMax: this.windSpeedMax,
      windDirection: this.windDirection,
      solarRadiation: this.solarRadiation,
      solarRadiationTotal: this.solarRadiationTotal,
      pressure: this.pressure,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
    }
  }
}
