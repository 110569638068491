import { SensorStateType } from './state'

export interface SensorGettersType {
  getNameSensorById: (state: SensorStateType) => (id: string) => SensorStateType['sensor']
  getSensorIdByField: (state: SensorStateType) => (field: string) => SensorStateType['sensor']
  getFieldSensorById: (state: SensorStateType) => (id: string) => SensorStateType['sensor']
  getUnitMeasureNormalizedById: (
    state: SensorStateType,
  ) => (id: string) => SensorStateType['sensor']
  getUnitTypeSensorById: (state: SensorStateType) => (id: string) => SensorStateType['sensor']
  getTypeSensorById: (state: SensorStateType) => (id: string) => SensorStateType['sensor']
  getSensorById: (state: SensorStateType) => (id: string) => SensorStateType['sensor']
  getSensorByField: (state: SensorStateType) => (field: string) => SensorStateType['sensor']
}

export const SensorGetters = {
  //getSensorById: (state: SensorStateType) => state.sensor,
  getNameSensorById: (state: SensorStateType) => (id: string) => {
    const sensor = state.sensors.find(sensor => sensor.id === id)
    return sensor?.name
  },
  getSensorIdByField: (state: SensorStateType) => (field: string) => {
    const sensor = state.sensors.find(sensor => sensor.field === field)
    return sensor?.id
  },
  getFieldSensorById: (state: SensorStateType) => (id: string) => {
    const sensor = state.sensors.find(sensor => sensor.id === id)
    return sensor?.field
  },
  getUnitMeasureNormalizedById: (state: SensorStateType) => (id: string) => {
    const sensor = state.sensors.find(sensor => sensor.id === id)
    return sensor?.unitMeasureNormalized
  },
  getUnitTypeSensorById: (state: SensorStateType) => (id: string) => {
    const sensor = state.sensors.find(sensor => sensor.id === id)
    return sensor?.unitType
  },
  getTypeSensorById: (state: SensorStateType) => (id: string) => {
    const sensor = state.sensors.find(sensor => sensor.id === id)
    return sensor?.type
  },
  getSensorById: (state: SensorStateType) => (id: string) => {
    return state.sensors.find(sensor => sensor.id === id)
  },
  getSensorByField: (state: SensorStateType) => (field: string) => {
    return state.sensors.find(sensor => sensor.field === field)
  },
}
