import { Sensor } from '../models/sensor'
export interface SensorStateType {
  sensors: Sensor[]
  sensor: Sensor | null
}

const SensorState: () => SensorStateType = () => ({
  sensors: [],
  sensor: null,
})

export default SensorState
