import { AlarmLog } from '../models/alarmLog'
export interface AlarmLogStateType {
  alarmLogs: AlarmLog[]
  alarmLog: AlarmLog | null
}

const AlarmLogState: () => AlarmLogStateType = () => ({
  alarmLogs: [],
  alarmLog: null,
})

export default AlarmLogState
