import { convertDatesToISO } from '@/config/aux'
import { Alarm } from '../models/alarm'
import { AlarmStateType } from './alarmState'
import axios from 'axios'

export interface AlarmActionsType {
  getAlarms(): Promise<void>
  getAlarmsByUserId(userId: string): Promise<void>
  postAlarm(alarm: Alarm): Promise<void>
  putAlarm(alarm: Alarm): Promise<void>
  deleteAlarm(id: string): Promise<void>
}

export const AlarmActions = {
  async getAlarms(this: AlarmStateType) {
    try {
      this.alarms = []
      const query = 'https://aqfcore-api.aquaflow.cl/alarms'
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((alarm: Alarm) => {
            this.alarms.push(alarm)
          })
        }
      })
    } catch (error) {
      console.error('Error getting Alarms', error)
    }
  },
  async getAlarmsByUserId(this: AlarmStateType, userId: string) {
    try {
      this.alarms = []
      const query = `https://aqfcore-api.aquaflow.cl/alarms?userId=${userId}`
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((alarm: Alarm) => {
            this.alarms.push(alarm)
          })
        }
      })
    } catch (error) {
      console.error('Error getting Alarms', error)
    }
  },
  async postAlarm(this: AlarmStateType, alarm: Alarm) {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/alarms'
      const data = convertDatesToISO(alarm.toPlainObject())

      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.alarms.push(alarm)
        }
      })
    } catch (error) {
      console.error('Error posting Alarm', error)
    }
  },
  async putAlarm(this: AlarmStateType, alarm: Alarm) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/alarms`
      const data = convertDatesToISO(alarm.toPlainObject())
      //const dataJson = JSON.stringify(data)

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.alarms = this.alarms.map(a => (a.id === alarm.id ? alarm : a))
          }
        })
    } catch (error) {
      console.error('Error updating Alarm', error)
    }
  },
  async deleteAlarm(this: AlarmStateType, id: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/alarms/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.alarms = this.alarms.filter(a => a.id !== alarm.id)
        }
      })
    } catch (error) {
      console.error('Error deleting Alarm', error)
    }
  },
}
