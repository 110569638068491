import { convertDatesToISO } from '@/config/aux'
import { Location } from '../models/location'
import { LocationStateType } from './state'
import axios from 'axios'

export interface LocationActionsType {
  getLocations(): Promise<void>
  getLocationsByCompanyId(companyId: string): Promise<void>
  postLocation(location: Location): Promise<void>
  putLocation(location: Location): Promise<void>
  deleteLocation(id: string): Promise<void>
}

export const LocationActions = {
  async getLocations(this: LocationStateType) {
    try {
      this.locations = []
      const query = 'https://aqfcore-api.aquaflow.cl/locations'
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((location: Location) => {
            this.locations.push(location)
          })
        }
      })
    } catch (error) {
      console.error('Error getting Locations', error)
    }
  },
  async getLocationsByCompanyId(this: LocationStateType, companyId: string) {
    try {
      this.locations = []
      const query = 'https://aqfcore-api.aquaflow.cl/locations?companyId=' + companyId
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((location: Location) => {
            this.locations.push(location)
          })
        }
      })
    } catch (error) {
      console.error('Error getting Locations', error)
    }
  },
  async postLocation(this: LocationStateType, location: Location) {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/locations'

      const data = convertDatesToISO(location.toPlainObject())

      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.locations.push(location)
        }
      })
    } catch (error) {
      console.error('Error posting Location', error)
    }
  },
  async putLocation(this: LocationStateType, location: Location) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/locations`
      const data = convertDatesToISO(location.toPlainObject())

      //const dataJson = JSON.stringify(data)

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.locations = this.locations.map(a => (a.id === location.id ? location : a))
          }
        })
    } catch (error) {
      console.error('Error updating Location', error)
    }
  },
  async deleteLocation(this: LocationStateType, id: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/locations/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.locations = this.locations.filter(a => a.id !== location.id)
        }
      })
    } catch (error) {
      console.error('Error deleting Location', error)
    }
  },
}
