<script lang="ts">
import { defineComponent } from 'vue'
import AuthLayout from './layouts/AuthLayout.vue'
import DefaultLayout from './layouts/DefaultLayout.vue'
import { useRoute } from 'vue-router'
import { computed, watch } from 'vue'
import { useThemeStore } from './stores/theme'
import { useTheme } from 'vuetify'
import { useAuthStore } from './views/Authentication/store'

export default defineComponent({
  name: 'App',
  components: {
    DefaultLayout,
    AuthLayout,
  },
  setup() {
    const route = useRoute()
    const authStore = useAuthStore()
    const isRouterLoaded = computed(() => route.name !== null)
    const themeStore = useThemeStore()
    const modeTheme = computed(() => themeStore.currentTheme)
    const theme = useTheme()

    const isProfileLoaded = computed(() => authStore.userProfile?.role !== '')

    const currentLayout = computed(() => {
      if (!isProfileLoaded.value && route.meta.layout !== 'auth') return 'loading'
      const layout = (route.meta.layout as string) || 'default'
      return layout === 'auth' ? 'AuthLayout' : 'DefaultLayout'
    })

    watch(
      () => themeStore.currentTheme,
      newTheme => {
        theme.global.name.value = newTheme
      },
      { immediate: true },
    )

    return {
      modeTheme,
      isRouterLoaded,
      currentLayout,
      isProfileLoaded,
    }
  },
})
</script>

<template>
  <v-app>
    <v-theme-provider :theme="modeTheme">
      <template v-if="isRouterLoaded">
        <component :is="currentLayout">
          <template v-if="currentLayout === 'loading'">
            <div class="loading">
              <v-progress-circular size="60" class="mx-auto my-auto" indeterminate color="primary" />
            </div>
          </template>
          <template v-else>
            <transition name="fade" mode="in-out">
              <router-view />
            </transition>
          </template>
        </component>
      </template>
    </v-theme-provider>
  </v-app>
</template>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}
</style>
