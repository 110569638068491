export function convertDatesToISO(obj: any): any {
  if (obj === null || obj === undefined) return obj

  if (obj instanceof Date) {
    return obj.toISOString()
  }

  if (typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = convertDatesToISO(obj[key])
      }
    }
  }

  return obj
}
export function generateUUID(): string {
  // Public Domain/MIT
  let d: number = new Date().getTime() // Timestamp
  let d2: number =
    (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0 // Time in microseconds since page-load or 0 if unsupported

  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r: number = Math.random() * 16 // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function getSensorIcon(name: string): string {
  const sensorIcons: Record<string, string> = {
    level: 'mdi-ruler',
    caudalLs: 'mdi-waves',
    battery: 'mdi-battery',
    velocity: 'mdi-speedometer',
    rain: 'mdi-weather-pouring',
    temp: 'mdi-thermometer',
    turbidity: 'mdi-water-alert',
    windSpeed: 'mdi-weather-windy-variant',
  }
  return sensorIcons[name] || 'mdi-help-circle'
}

export function setNameComparationOperation(operation: string) {
  const operationNames: Record<string, string> = {
    '=': 'Igual a',
    '>': 'Mayor que',
    '>=': 'Mayor o igual que',
    '<': 'Menor que',
    '<=': 'Menor o igual que',
  }
  return operationNames[operation] || 'No definido'
}

export function setNameLogicalOperation(operation: string) {
  const operationNames: Record<string, string> = {
    and: 'Y',
    or: 'O',
  }
  return operationNames[operation] || 'No definido'
}

export function getTimeDifferenceFromNow(isoString: string) {
  if (!isoString) return { minutes: 0, hours: 0, days: 0 }
  const inputDate = new Date(
    isoString.substring(0, 4) +
      '-' +
      isoString.substring(5, 7) +
      '-' +
      isoString.substring(8, 10) +
      'T' +
      isoString.substring(11, 13) +
      ':' +
      isoString.substring(14, 16) +
      ':' +
      isoString.substring(17, 19),
  )
  const now = new Date()

  const diffInMs = new Date(now).getTime() - inputDate.getTime()
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60))
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60))
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

  return {
    minutes: diffInMinutes,
    hours: diffInHours,
    days: diffInDays,
  }
}
export function getFormattedTimeDifferenceFromNow(isoString: string): string {
  const { minutes, hours, days } = getTimeDifferenceFromNow(isoString)

  let result = ''
  if (days > 0) {
    result += `${days} días `
  }
  if (hours > 0) {
    result += `${hours % 24} horas `
  }
  if (minutes > 0) {
    result += `${minutes % 60} minutos`
  }

  return result.trim()
}

export function setGtmOffsetDatetimeString(dateString: string, gtm: string): string {
  const date = new Date(dateString)
  date.setHours(date.getHours() + parseInt(gtm))
  return date.toISOString()
}
export function setChileanDatetimeToStringByISO(
  isoDate: string | null | undefined,
): string | undefined {
  if (!isoDate) return

  const timestamp = new Date(
    isoDate.substring(0, 4) +
      '-' +
      isoDate.substring(5, 7) +
      '-' +
      isoDate.substring(8, 10) +
      'T' +
      isoDate.substring(11, 13) +
      ':' +
      isoDate.substring(14, 16) +
      ':' +
      isoDate.substring(17, 19),
  )

  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ]

  const day = timestamp.getDate()
  const month = months[timestamp.getMonth()]
  const year = timestamp.getFullYear()

  const hour = String(timestamp.getHours()).padStart(2, '0')
  const minutes = String(timestamp.getMinutes()).padStart(2, '0')

  // const setDate = `${day} de ${month} de ${year} a las ${hour}:${minutes} hrs`
  const setDate = `${day}, ${month.substring(0, 3)} ${year.toString().substring(2, 4)}, ${hour}:${minutes} hrs`

  return setDate
}

//funcion para reemplza . por , en los numeros
export function replaceDotByComma(value: number): string {
  return value?.toString().replace('.', ',')
}
