import { convertDatesToISO } from '@/config/aux'
import { UserAppPreference } from '../models/userAppPreference'
import { UserAppPreferenceStateType } from './state'

import axios from 'axios'

export interface UserAppPreferenceActionsType {
  getUserAppPreferences(userId: string): Promise<UserAppPreference | null>
  postUserAppPreference(userAppPreference: UserAppPreference, userId: string): Promise<void>
  putUserAppPreference(userAppPreference: UserAppPreference, userId: string): Promise<void>
  deleteUserAppPreference(id: string, userId: string): Promise<void>
}

export const UserAppPreferenceActions = {
  async getUserAppPreferences(
    this: UserAppPreferenceStateType,
    userId: string,
  ): Promise<UserAppPreference | null> {
    try {
      let userAppPreference: UserAppPreference | null = null
      const query = `https://aqfcore-api.aquaflow.cl/user/${userId}/apppreferences`
      await axios.get(query).then(response => {
        if (response.status === 200) {
          userAppPreference = response.data as UserAppPreference
          this.userAppPreference = structuredClone(userAppPreference)
        } else {
          return null
        }
      })
      return userAppPreference
    } catch (error) {
      console.error('Error getting UserAppPreferences', error)
      return null
    }
  },

  async postUserAppPreference(
    this: UserAppPreferenceStateType,
    userAppPreference: UserAppPreference,
    userId: string,
  ) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/user/${userId}/apppreferences`
      const data = convertDatesToISO(userAppPreference.toPlainObject())
      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.userAppPreferences.push(userAppPreference)
        }
      })
    } catch (error) {
      console.error('Error posting UserAppPreference', error)
    }
  },
  async putUserAppPreference(
    this: UserAppPreferenceStateType,
    userAppPreference: UserAppPreference,
    userId: string,
  ) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/user/${userId}/apppreferences`
      const data = convertDatesToISO(userAppPreference)
      //const dataJson = JSON.stringify(data)

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.userAppPreferences = this.userAppPreferences.map(a => (a.id === userAppPreference.id ? userAppPreference : a))
          }
        })
    } catch (error) {
      console.error('Error updating UserAppPreference', error)
    }
  },
  async deleteUserAppPreference(this: UserAppPreferenceStateType, id: string, userId: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/user/${userId}/apppreferences/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.userAppPreferences = this.userAppPreferences.filter(a => a.id !== userAppPreference.id)
        }
      })
    } catch (error) {
      console.error('Error deleting UserAppPreference', error)
    }
  },
}
