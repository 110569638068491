import { Measurement } from '../models/measurement'
export interface MeasurementStateType {
  measurements: Measurement[]
  measurement: Measurement | null
  measurementRealTime: Measurement | null
}

const MeasurementState: () => MeasurementStateType = () => ({
  measurements: [],
  measurement: null,
  measurementRealTime: null,
})

export default MeasurementState
