import { convertDatesToISO } from '@/config/aux'
import { StationFile } from '../models/stationFile'
import { StationFileStateType } from './state'
import axios from 'axios'

export interface StationFileActionsType {
  getStationFiles(): Promise<void>
  getStationFilesByStationIds(stationIds: string[]): Promise<void>
  postStationFile(stationFile: StationFile): Promise<void>
  postApplySensorNormalization(
    sensorNormalizationId: string,
    sensorId: string,
    stationFileId: string,
  ): Promise<void>
  putStationFile(stationFile: StationFile): Promise<void>
  deleteStationFile(id: string): Promise<void>
}

export const StationFileActions = {
  async getStationFiles(this: StationFileStateType) {
    try {
      this.stationFiles = []
      const query = 'https://aqfcore-api.aquaflow.cl/stationfiles'
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((stationFile: StationFile) => {
            this.stationFiles.push(stationFile)
          })
        }
      })
    } catch (error) {
      console.error('Error getting StationFiles', error)
    }
  },
  async getStationFilesByStationIds(this: StationFileStateType, stationIds: string[]) {
    try {
      this.stationFiles = []
      const query = 'https://aqfcore-api.aquaflow.cl/stationfiles/stationids'

      await axios.post(query, stationIds).then(response => {
        if (response.status === 200) {
          this.stationFiles = response.data as StationFile[]
        }
      })
    } catch (error) {
      console.error('Error posting StationFile', error)
    }
  },
  async getStationFilesByStations(this: StationFileStateType, stationIds: string[]) {
    try {
      this.stationFiles = []
      const query = `https://aqfcore-api.aquaflow.cl/stationfiles?stations=${stationIds.join(',')}`
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((stationFile: StationFile) => {
            this.stationFiles.push(stationFile)
          })
        }
      })
    } catch (error) {
      console.error('Error getting StationFiles', error)
    }
  },
  async postStationFile(this: StationFileStateType, stationFile: StationFile) {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/stationfiles'
      const data = convertDatesToISO(stationFile.toPlainObject())

      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.stationFiles.push(stationFile)
        }
      })
    } catch (error) {
      console.error('Error posting StationFile', error)
    }
  },
  async postApplySensorNormalization(
    sensorNormalizationId: string,
    sensorId: string,
    stationFileId: string,
  ) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/raw-measurements/apply-normalization/${stationFileId}/${sensorId}/${sensorNormalizationId}`
      await axios.post(query).then(response => {
        if (response.status === 200) {
          console.log('Normalization applied')
          console.log('response data', response.data)
        }
      })
    } catch (error) {
      console.error('Error updating StationFile', error)
    }
  },
  async putStationFile(this: StationFileStateType, stationFile: StationFile) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/stationfiles`
      const data = convertDatesToISO(stationFile)
      //const dataJson = JSON.stringify(data)

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.stationFiles = this.stationFiles.map(a => (a.id === stationFile.id ? stationFile : a))
          }
        })
    } catch (error) {
      console.error('Error updating StationFile', error)
    }
  },
  async deleteStationFile(this: StationFileStateType, id: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/stationfiles/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.stationFiles = this.stationFiles.filter(a => a.id !== stationFile.id)
        }
      })
    } catch (error) {
      console.error('Error deleting StationFile', error)
    }
  },
}
