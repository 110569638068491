import { defineStore } from 'pinia'
import StationFileState, { StationFileStateType } from './state'
import { StationFileActions, StationFileActionsType } from './actions'
import { StationFileGetters } from './getters'

export const useStationFileStore = defineStore<
  'stationFile',
  StationFileStateType,
  typeof StationFileGetters,
  StationFileActionsType
>('stationFile', {
  state: StationFileState,
  actions: StationFileActions,
  getters: StationFileGetters,
})
