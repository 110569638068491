import { defineStore } from 'pinia'
import LocationState, { LocationStateType } from './state'
import { LocationActions, LocationActionsType } from './actions'
import { LocationGetters } from './getters'

export const useLocationStore = defineStore<
  'location',
  LocationStateType,
  typeof LocationGetters,
  LocationActionsType
>('location', {
  state: LocationState,
  actions: LocationActions,
  getters: LocationGetters,
})
