import { AlarmLog } from './AlarmLog/models/alarmLog'
import { Condition } from './models/alarm'

export interface ActivatedCondition {
  value: number
  condition: Condition
}

export function getActivatedConditions(
  conditions: Condition[],
  log: AlarmLog,
): ActivatedCondition[] {
  const logValues: (number | null)[] = [
    log.value,
    log.value1,
    log.value2,
    log.value3,
    log.value4,
    log.value5,
    log.value6,
    log.value7,
    log.value8,
  ]
  if (logValues.length < conditions.length) return []
  return conditions
    .filter(condition => {
      const logValue = logValues[condition.position]
      if (logValue === null) return false

      switch (condition.operation) {
        case '>':
          return logValue > condition.valueNormalized
        case '<':
          return logValue < condition.valueNormalized
        case '>=':
          return logValue >= condition.valueNormalized
        case '<=':
          return logValue <= condition.valueNormalized
        case '==':
          return logValue === condition.valueNormalized
        case '!=':
          return logValue !== condition.valueNormalized
        default:
          return false
      }
    })
    .map(condition => ({
      value: logValues[condition.position] as number,
      condition,
    }))
}
