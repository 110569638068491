import { initializeApp, FirebaseApp } from 'firebase/app'
import { getAuth, Auth } from 'firebase/auth'
import { getFirestore, Firestore } from 'firebase/firestore'
import { getStorage, FirebaseStorage } from 'firebase/storage'

interface FirebaseConfig {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
}

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.VUE_APP_FIREBASE_APP_ID || '',
}

const app: FirebaseApp = initializeApp(firebaseConfig)

const auth: Auth = getAuth(app)
const storage: FirebaseStorage = getStorage(app)
const db: Firestore = getFirestore(app)

export { auth, db, storage }
