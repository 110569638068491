<script lang="ts" setup>
import { computed, defineProps } from 'vue'

import { Alarm } from '../models/alarm'
//data
//store

const props = defineProps<{
  alarms: Alarm[]
}>()

//computed

const alarmsActive = computed(() => props.alarms.filter((alarm: Alarm) => alarm.active === true))

//lifecycle
</script>
<template>

  <v-card class="custom-card " :class="alarmsActive?.length > 0 ? 'bg-error' : 'bg-primary'">
    <div class="bubbles">
      <div class="bubble large"></div>
      <div class="bubble small"></div>
    </div>
    <div class="card-content">
      <h1>{{ alarmsActive?.length }} {{ alarmsActive?.length == 1 ? 'Alarma activa' : 'Alarmas activas' }}</h1>
      <p>Total {{ alarms?.length }}</p>
    </div>
  </v-card>
</template>
