import { defineStore } from 'pinia'
import SensorState, { SensorStateType } from './state'
import { SensorActions, SensorActionsType } from './actions'
import { SensorGetters } from './getters'

export const useSensorStore = defineStore<
  'sensor',
  SensorStateType,
  typeof SensorGetters,
  SensorActionsType
>('sensor', {
  state: SensorState,
  actions: SensorActions,
  getters: SensorGetters,
})
