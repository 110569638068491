import { defineStore } from 'pinia'
import MeasurementState, { MeasurementStateType } from './state'
import { MeasurementActions, MeasurementActionsType } from './actions'
import { MeasurementGetters } from './getters'

export const useMeasurementStore = defineStore<
  'measurement',
  MeasurementStateType,
  typeof MeasurementGetters,
  MeasurementActionsType
>('measurement', {
  state: MeasurementState,
  actions: MeasurementActions,
  getters: MeasurementGetters,
})
