import { ChartSetting } from '../ChartSetting/models/chartSetting'
import { Measurement } from './models/measurement'
import { convertUnit } from './normalizationMeasurement'
import { Sensor } from '../Sensor/models/sensor'
import { Column } from '../Station/StationFile/models/stationFile'
import { setChileanDatetimeToStringByISO } from '@/config/aux'
import { getConversiontByTypeAndConvertionKey } from '../Sensor/utils'

const getMeasurementData = (
  measurements: Measurement[],
  variableName: string,
  sensor: Sensor,
  column: Column,
): (number | string | Date)[] => {
  return measurements
    .slice()
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(measurement => {
      let value: string | number | Date | null = null
      if (variableName === 'water_velocity') {
        variableName = 'waterVelocity'
      }
      if (variableName !== 'date' && column?.unitMeasureView) {
        const rawValue = measurement[variableName as keyof Measurement]
        if (typeof rawValue === 'number') {
          value = convertUnit(
            rawValue,
            sensor.unitMeasureNormalized,
            column.unitMeasureView,
            sensor.unitType,
          )
        }
      } else {
        if (variableName === 'date') {
          const dateValue = measurement[variableName]

          // Aseguramos que dateValue no es undefined antes de usarlo
          if (dateValue !== undefined) {
            value = new Date(dateValue)
            // Convertir a fecha
            value = setChileanDatetimeToStringByISO(dateValue as string) || 'error data'
          } else {
            value = 'error data' // O manejar el caso donde la fecha no esté presente
          }
        }
      }
      if (typeof value === 'string' || typeof value === 'number') {
        return value
      } else {
        return 'error data'
        //throw new Error(`Unexpected type for measurement value: ${typeof value}`)
      }
    })
}

export const buildChartsV2 = async (
  originalChartSetting: ChartSetting,
  measurements: Measurement[],
  sensors: Sensor[],
  columns: Column[],
  dataZoom: boolean,
  darkMode: boolean,
) => {
  let chartData = null

  let dataZoomConfig = [
    {
      show: true,
      realtime: true,
      start: 30,
      end: 100,
      xAxisIndex: [0],
      textStyle: {
        color: darkMode ? '#fff' : '#134c6e',
      },
    },
  ]

  if (originalChartSetting?.id) {
    const chartSetting = JSON.parse(JSON.stringify(originalChartSetting))
    chartSetting.tooltip.formatter = function (params: any) {
      let xAxisValue = ''

      // Buscar el valor del eje X entre los parámetros
      if (params.length > 0 && params[0].axisValue !== undefined) {
        const xAxisFormattedValue = Number.isInteger(params[0].axisValue)
          ? params[0].axisValue.toLocaleString('es') // Sin decimales si es entero
          : params[0].axisValue.toLocaleString('es', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) // Con 2 decimales si tiene parte decimal
        xAxisValue = `
  <div style="
      margin: 5px 0; 
      display: flex; 
      align-items: center;
  ">
      <span>
           ${xAxisFormattedValue}
      </span>
  </div>
`
      }

      // Construir los valores de las series
      const seriesContent = params
        .map((item: any) => {
          let columnSearch = columns.find(column => column.sensorId === item.seriesId)
          let unit = columnSearch?.unitMeasureView || ''

          unit =
            getConversiontByTypeAndConvertionKey(
              sensors.find(sensor => sensor.id === item.seriesId)?.unitType || '',
              unit,
            )?.unit || unit
          // Formatear el valor numérico (con coma como separador decimal)
          const formattedValue = Number.isInteger(item.value)
            ? item.value.toLocaleString('es') // Sin decimales si es entero
            : item.value.toLocaleString('es', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) // Con 2 decimales si tiene parte decimal

          return `
  <div style="
      margin: 5px 0; 
      display: flex; 
      align-items: center;
  ">
      <span style="
          display: inline-block; 
          width: 10px; 
          height: 10px; 
          background-color: ${item.color}; 
          border-radius: 50%; 
          margin-right: 8px;">
      </span>
      <span>
          ${item.seriesName}: <b>${formattedValue}</b> ${unit}
      </span>
  </div>
`
        })
        .join('')

      // Combinar el valor del eje X y las series en el tooltip final
      return xAxisValue + seriesContent
    }

    //ajusta los labels de los yAxis
    chartSetting.yAxis.forEach((yAxis: any) => {
      let sensorSearch = sensors.find(sensor => sensor.id === yAxis.name)
      let columnSearch = columns.find(column => column.sensorId === yAxis.name)
      yAxis.axisLabel.formatter = function (value: any) {
        const formattedValue = Number.isInteger(value)
          ? value.toLocaleString('es')
          : value.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        return formattedValue
      }
      yAxis.name =
        sensorSearch?.name +
          ' ' +
          getConversiontByTypeAndConvertionKey(
            sensorSearch?.unitType || '',
            columnSearch?.unitMeasureView || '',
          )?.unit || yAxis.name

      //si es dark model el axisLine es blanco
      if (darkMode) {
        yAxis.axisLine.lineStyle.color = '#fff'
      }
    })
    //ajusta los labels de los xAxis en modo dark
    if (darkMode) {
      chartSetting.xAxis.axisLabel = {
        color: '#FFF',
        width: 1,
        type: '',
      }
    }
    chartSetting.series = chartSetting.series.map((serie: any) => {
      const newSerie = { ...serie }
      let sensorFieldName = sensors.find(sensor => sensor.id === serie.sensorId)?.field
      let columnSearch = columns.find(column => column.sensorId === serie.sensorId)
      if (!columnSearch) {
        console.log('column not found', serie.sensorId)
        throw new Error(`Column with sensorId ${serie.sensorId} not found`)
      }
      let sensor = sensors.find(sensor => sensor.id === serie.sensorId)
      if (!sensor) {
        console.log('sensor not found', serie.sensorId)
        throw new Error(`Sensor with id ${serie.sensorId} not found`)
      }
      newSerie.data = getMeasurementData(measurements, sensorFieldName || '', sensor, columnSearch)

      newSerie.id = sensor?.id
      newSerie.name = sensor?.name

      delete newSerie.customPosition
      delete newSerie.customOrder
      delete newSerie.sensorId
      delete newSerie.label
      delete newSerie.endLabel
      return newSerie
    })
    //para usar data zoom
    if (dataZoom) {
      chartSetting.dataZoom = dataZoomConfig
    }
    delete chartSetting.xAxis.sensorId
    delete chartSetting.id
    delete chartSetting.sensorGroupIds
    delete chartSetting.createdAt
    delete chartSetting.updatedAt
    delete chartSetting.createdBy
    delete chartSetting.updatedBy
    delete chartSetting.title

    chartSetting.xAxis.data = getMeasurementData(measurements, 'date', sensors[0], columns[0])
    chartData = chartSetting
  }
  return chartData
}
