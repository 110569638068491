import { AlarmStateType } from './alarmState'
import { useStationStore } from '@/views/Station/store'
export interface AlarmGettersType {
  getAlarmById: (state: AlarmStateType) => (id: string) => AlarmStateType['alarm']

  getNameStationByAlarmId: (state: AlarmStateType) => (id: string) => AlarmStateType['alarm']
  getNameAlarmById: (state: AlarmStateType) => (id: string) => AlarmStateType['alarm']
}

export const AlarmGetters = {
  getAlarmById: (state: AlarmStateType) => (id: string) => {
    return state.alarms.find(alarm => alarm.id === id)
  },

  getNameStationByAlarmId: (state: AlarmStateType) => (id: string) => {
    const alarm = state.alarms.find(alarm => alarm.id === id)
    const stationStore = useStationStore()
    const station = stationStore.getStationById(alarm?.stationId || '')
    return station?.name
  },
  getNameAlarmById: (state: AlarmStateType) => (id: string) => {
    const alarm = state.alarms.find(alarm => alarm.id === id)
    return alarm?.name
  },
}
