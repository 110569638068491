import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthLogin from '../views/Authentication/SignIn/LoginPage.vue'
import { auth } from '@/config/firebase'
import { useAuthStore } from '@/views/Authentication/store'

import { CustomRouteMeta } from './types'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/auth/login',
    name: 'login',
    component: AuthLogin,
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/monitoring-panel',
    name: 'monitoring-panel',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/MonitoringPanel/MonitoringPanel.vue'),
  },
  {
    path: '/chart-settings',
    name: 'chart-settings',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ChartSetting/ChartSettingPage.vue'),
  },
  {
    path: '/dga-logs',
    name: 'dga-logs',
    component: () => import(/* webpackChunkName: "dga-logs" */ '../views/Dga/DgaLogsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/dga-customer',
    name: 'dga-customer',
    component: () => import(/* webpackChunkName: "dga-logs" */ '../views/Dga/DgaCPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/dga-credentials',
    name: 'dga-credentials',
    component: () =>
      import(/* webpackChunkName: "dga-logs" */ '../views/Dga/DgaCredential/DgaCredentialPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/locations',
    name: 'locations',
    component: () =>
      import(/* webpackChunkName: "locations" */ '../views/Location/LocationsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/locations-customer',
    name: 'locations-customer',
    component: () =>
      import(/* webpackChunkName: "locations" */ '../views/Location/LocationsCPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: () =>
      import(/* webpackChunkName: "locations" */ '../views/Company/CompaniesPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/stations',
    name: 'stations',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/Station/StationsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/stations/:id',
    name: 'station',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/Station/StationPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/stations-customer',
    name: 'stations-customer',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/Station/StationsCPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/station-discharge-curve',
    name: 'station-discharge-curve',
    component: () =>
      import(
        /* webpackChunkName: "alarms" */ '../views/Station/StationDischargeCurve/StationDischargeCurvesPage.vue'
      ),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/station-files',
    name: 'station-files',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/Station/StationFile/StationFilesPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/load-station-files',
    name: 'load-station-files',
    component: () =>
      import(
        /* webpackChunkName: "alarms" */ '../views/Station/LoadStationFile/LoadStationFilesPage.vue'
      ),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/sensors',
    name: 'sensors',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/Sensor/SensorsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/sensor-groups',
    name: 'sensor-groups',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/Sensor/SensorGroup/SensorGroupsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/sensor-stations',
    name: 'sensor-stations',
    component: () =>
      import(
        /* webpackChunkName: "alarms" */ '../views/Sensor/SensorInStation/SensorInStationsPage.vue'
      ),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/sensor-normalizations',
    name: 'sensor-normalizations',
    component: () =>
      import(
        /* webpackChunkName: "alarms" */ '../views/Sensor/SensorNormalization/SensorNormalizationsPage.vue'
      ),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/equips',
    name: 'equips',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/Equip/EquipsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/quotes',
    name: 'quotes',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/Quote/QuotesPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/width-methods',
    name: 'width-methods',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/WidthMethod/WidthMethodsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/watersheds',
    name: 'watersheds',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/Watershed/WatershedsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },

  {
    path: '/water-systems',
    name: 'water-systems',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/WaterSystem/WaterSystemsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/User/UsersPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/User/CustomersPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/User/UserPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/measurement-methods',
    name: 'measurement-methods',
    component: () =>
      import(
        /* webpackChunkName: "alarms" */ '../views/MeasurementMethod/MeasurementMethodsPage.vue'
      ),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },

  {
    path: '/network-logs',
    name: 'network-logs',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/NetworkLog/NetworkLogsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  //alarms
  {
    path: '/alarms',
    name: 'alarms',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/Alarm/AlarmsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/alarms-customer',
    name: 'alarms-customer',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/Alarm/AlarmsCPage.vue'),
    meta: {
      requireAuth: true,
      role: ['user'],
    },
  },
  {
    path: '/alarm-logs',
    name: 'alarm-logs',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/Alarm/AlarmLog/AlarmLogsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/alarm-report-files',
    name: 'alarm-report-files',
    component: () =>
      import(
        /* webpackChunkName: "alarms" */ '../views/Alarm/AlarmReportFile/AlarmReportFilesPage.vue'
      ),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  //measurements
  {
    path: '/measurements',
    name: 'measurements',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/Measurement/MeasurementsPage.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/measurement-imports',
    name: 'measurement-imports',
    component: () =>
      import(/* webpackChunkName: "alarms" */ '../views/Measurement/ImportFile/ImportFile.vue'),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/measurement-visits',
    name: 'measurement-visits',
    component: () =>
      import(
        /* webpackChunkName: "alarms" */ '../views/MeasurementVisit/MeasurementVisitsPage.vue'
      ),
    meta: {
      requireAuth: true,
      role: ['admin', 'user'],
    },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/UnauthorizedPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const waitForUserData = async (authStore: ReturnType<typeof useAuthStore>): Promise<void> => {
  return new Promise(resolve => {
    if (authStore.userProfile && authStore.userProfile.role) {
      resolve()
      return
    }

    const checkUserData = setInterval(() => {
      if (authStore.userProfile && authStore.userProfile.role) {
        clearInterval(checkUserData)
        resolve()
      }
    }, 100)
  })
}

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const authStore = useAuthStore()
    const user = auth.currentUser

    if (user) {
      await waitForUserData(authStore)
    }

    if ((to.meta as CustomRouteMeta).requireAuth) {
      if (!user) {
        next({ path: '/auth/login' })
      } else {
        const requiredRole = (to.meta as CustomRouteMeta).role || []
        if (requiredRole.includes(authStore.userProfile?.role || '')) {
          next()
        } else {
          next({ path: '/unauthorized' })
        }
      }
    } else {
      next()
    }
  },
)

export default router
