import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia'
import { auth, db } from './config/firebase'
import { useAuthStore } from './views/Authentication/store'
import { onSnapshot, getDoc, doc, DocumentData } from 'firebase/firestore'
import { UserProfile } from './views/Authentication/store/types'
import { WebSocketService } from './services/websocket'
loadFonts()

const pinia = createPinia()

let app: ReturnType<typeof createApp> | undefined
let webSocketService: WebSocketService | null = null

auth.onAuthStateChanged(async user => {
  if (navigator.onLine) {
    await initializeApp(user)
  } else {
    const offlineUser = {
      email: 'hola@gonzalohonorato.cl',
      uid: 'Otk8sAvwjSWUzzIhx0BOkF9tHVq1',
      emailVerified: true,
    }
    await initializeApp(offlineUser)
  }
})

async function initializeApp(
  user: {
    email: string | null
    uid: string | null
    emailVerified: boolean
  } | null,
): Promise<void> {
  if (!app) {
    app = createApp(App)
    app.use(pinia)
    app.use(router)
    app.use(vuetify)
    app.mount('#app')
  }

  const { useAuthStore } = await import('./views/Authentication/store')
  const authStore = useAuthStore()
  const { useMeasurementStore } = await import('./views/Measurement/store')
  const measurementStore = useMeasurementStore()
  if (user) {
    const userDetec = {
      email: user.email ?? '',
      uid: user.uid ?? '',
      emailVerified: user.emailVerified,
    }
    if (navigator.onLine) {
      const userProfile = await getUserProfile(userDetec.uid, authStore)
      authStore.actionAddUserProfile({
        userProfile: userProfile as UserProfile,
      })
      if (!webSocketService) {
        webSocketService = new WebSocketService(userDetec.uid, userProfile?.companyId)
        webSocketService.setAuthStore(authStore)
        // se inicia para poder conecar el socket client
        webSocketService.setMeasurementStore(measurementStore)
        webSocketService.connect() // Conectar WebSocket
      }
    } else {
      const userData = localStorage.getItem('user')
      const userLocalProfile = userData
        ? JSON.parse(userData)
        : { name: '', uid: '', role: '', email: '', companyId: '' }

      if (userLocalProfile.uid) {
        authStore.actionAddUserProfile({ userProfile: userLocalProfile })
      } else {
        alert('Hay un error al recuperar el usuario desde el dispositivo')
        return
      }
    }
  } else {
    authStore.detectUser(null)
  }
}

async function getActiveSesion(
  userId: string,
  authStore: ReturnType<typeof useAuthStore>,
): Promise<void> {
  await onSnapshot(doc(db, 'users', userId), async doc => {
    if (!doc.data()?.active) {
      alert('Su sesión ha expirado')
      authStore.logOut()
    }
  })
}

async function getUserProfile(
  userId: string,
  authStore: ReturnType<typeof useAuthStore>,
): Promise<DocumentData | undefined> {
  const docRef = doc(db, 'users', userId)
  const docSnap = await getDoc(docRef)
  let userProfile: DocumentData | undefined
  if (docSnap.exists()) {
    userProfile = docSnap.data()
    await getActiveSesion(userId, authStore)
  }
  return userProfile
}
