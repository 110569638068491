import { Station } from '../models/station'

export interface StationStateType {
  stations: Station[]
  stationsGroup: Station[][]
  station: Station | null
}

const StationState: () => StationStateType = () => ({
  stations: [],
  stationsGroup: [],
  station: null,
})

export default StationState
