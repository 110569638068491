import { convertDatesToISO } from '@/config/aux'
import { Measurement } from '../models/measurement'
import { MeasurementStateType } from './state'
import axios from 'axios'

export interface MeasurementActionsType {
  getMeasurements(): Promise<void>
  getMeasurementsByStationAndFilters(data: any): Promise<Measurement[]>
  getRawMeasurementsByStationAndFilters(data: any): Promise<Measurement[]>
  postMeasurement(measurement: Measurement): Promise<void>
  postImportFileMeasurement(data: any): Promise<boolean>
  putMeasurement(measurement: Measurement): Promise<void>
  deleteMeasurement(id: string): Promise<void>
  addMeasurement(measurement: Measurement): void
}

export const MeasurementActions = {
  async getMeasurements(this: MeasurementStateType) {
    try {
      this.measurements = []

      const query = 'https://aqfcore-api.aquaflow.cl/measurements'
      const measurements: Measurement[] = []
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((measurement: Measurement) => {
            measurements.push(measurement)
          })
          measurements.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
          this.measurements = measurements
        }
      })
    } catch (error) {
      console.error('Error getting Measurements', error)
    }
  },
  async getMeasurementsByStationAndFilters(
    this: MeasurementStateType,
    data: { stationId: string; initDate: string; endDate: string; range: string; gtm: string },
  ): Promise<Measurement[]> {
    try {
      const measurements: Measurement[] = []
      const query = `https://aqfcore-api.aquaflow.cl/measurements/station/${data.stationId}/aggregated?startDate=${data.initDate}&endDate=${data.endDate}&rangeType=${data.range}`
      const gtmOffset = parseInt(data.gtm)

      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((measurement: Measurement) => {
            const date = new Date(measurement.date)
            date.setHours(date.getHours() + gtmOffset)
            measurement.date = date.toISOString()
            measurements.push(measurement)
          })
          measurements.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        }
      })
      return measurements
    } catch (error) {
      console.error('Error getting Measurements', error)
      return []
    }
  },
  async getRawMeasurementsByStationAndFilters(
    this: MeasurementStateType,
    data: { stationId: string; initDate: string; endDate: string; range: string; gtm: string },
  ): Promise<Measurement[]> {
    try {
      const measurements: Measurement[] = []
      const query = `https://aqfcore-api.aquaflow.cl/measurements/station/${data.stationId}?startDate=${data.initDate}&endDate=${data.endDate}`
      const gtmOffset = parseInt(data.gtm)

      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((measurement: Measurement) => {
            const date = new Date(measurement.date)
            date.setHours(date.getHours() + gtmOffset)
            measurement.date = date.toISOString()
            measurements.push(measurement)
          })
          measurements.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        }
      })
      return measurements
    } catch (error) {
      console.error('Error getting Measurements', error)
      return []
    }
  },
  async postMeasurement(this: MeasurementStateType, measurement: Measurement) {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/measurements'
      const data = convertDatesToISO(measurement)

      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.measurements.push(measurement)
        }
      })
    } catch (error) {
      console.error('Error posting Measurement', error)
    }
  },
  async postImportFileMeasurement(this: MeasurementStateType, data: any): Promise<boolean> {
    try {
      let status = false
      const query = 'https://aqfcore-api.aquaflow.cl/raw-measurements'

      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.measurements.push(measurement)
          status = true
        }
      })
      return status
    } catch (error) {
      console.error('Error posting Measurement', error)
      return false
    }
  },
  async putMeasurement(this: MeasurementStateType, measurement: Measurement) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/measurements`
      const data = convertDatesToISO(measurement)
      //const dataJson = JSON.stringify(data)

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.measurements = this.measurements.map(a => (a.id === measurement.id ? measurement : a))
          }
        })
    } catch (error) {
      console.error('Error updating Measurement', error)
    }
  },
  async deleteMeasurement(this: MeasurementStateType, id: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/measurements/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.measurements = this.measurements.filter(a => a.id !== measurement.id)
        }
      })
    } catch (error) {
      console.error('Error deleting Measurement', error)
    }
  },
  addMeasurement(this: MeasurementStateType, measurement: any) {
    const parsedMeasurement =
      typeof measurement === 'string' ? JSON.parse(measurement) : measurement

    this.measurementRealTime = parsedMeasurement
  },
}
