<script setup lang="ts">
import { onMounted, ref, nextTick, onUnmounted } from 'vue';
import 'leaflet/dist/leaflet.css';
import { Station } from '@/views/Station/models/station';
import router from '@/router';

interface Props {
    stations: Station[];
    height: string;
    activeTooltip: boolean;
}

const props = defineProps<Props>();
const cardContainer = ref<HTMLElement | null>(null);

const navigateToStation = (locationId: string) => {
    router.push(`/stations/${locationId}`);
};

onMounted(async () => {
    await nextTick();
    const windyDiv = document.createElement('div');
    windyDiv.id = 'windy';
    cardContainer.value?.appendChild(windyDiv);

    const options = {
        key: 'oMJmSFDRvg6FukUMkgm8wHOgxfAzgefS',
        lat: props.stations[0]?.lat ?? -34.760091,
        lon: props.stations[0]?.lng ?? -70.440845,
        zoom: 10,

        verbose: true,
        overlay: 'wind',
        level: 'surface',
        timestamp: Date.now(),

        // Configuración de capas disponibles
        overlays: [
            'wind',
            'temp',
            'rain',
            'pressure',
            'clouds'
        ],


        timeFormat: '24h',
        calendar: 'gregorian',
        hourFormat: '24h',
        displayHours: true,
        displayMinutes: true,
        displayMode: 'local',

        menu: {
            show: true,
            displayPosition: 'right'
        },

        enableControls: true,
        showControls: true,
        showToolbar: true,
        showMenu: true,
        showScale: true
    };

    // @ts-ignore
    window.windyInit(options, (windyAPI: any) => {
        const { map, store, overlays, picker } = windyAPI;
        console.log('Overlays permitidos:', store.getAllowed('overlay'));
        props.stations.forEach(station => {
            // @ts-ignore
            L.marker([station.lat, station.lng])
                .bindPopup(
                    props.activeTooltip
                        ? `<div class="station-popup">
                <h3>${station.name}</h3>
                <button 
                  onclick="window.dispatchEvent(new CustomEvent('navigate-to-station', { detail: '${station.locationId}' }))"
                  class="station-button"
                >
                  Ver detalles
                </button>
              </div>`
                        : station.name
                )
                .addTo(map);
        });

        const changeOverlay = (overlayName: string) => {
            store.set('overlay', overlayName);
        };

        const createLayerControls = () => {
            const controls = [
                { name: 'Viento', overlay: 'wind' },
                { name: 'Temperatura', overlay: 'temp' },
                { name: 'Lluvia', overlay: 'rain' },
                { name: 'Presión', overlay: 'pressure' },
                { name: 'Nubes', overlay: 'clouds' }
            ];

            const controlsContainer = document.createElement('div');
            controlsContainer.className = 'custom-layer-controls';

            controls.forEach(control => {
                const button = document.createElement('button');
                button.className = 'layer-control-button';
                button.textContent = control.name;
                button.onclick = () => changeOverlay(control.overlay);
                controlsContainer.appendChild(button);
            });

            map.getContainer().appendChild(controlsContainer);
        };

        createLayerControls();
        map.invalidateSize();


        const forceControlsVisibility = () => {
            document.querySelectorAll('.leaflet-control-container, .weather-controls, .controls-floating')
                .forEach(el => {
                    if (el instanceof HTMLElement) {
                        el.style.display = 'block';
                        el.style.visibility = 'visible';
                        el.style.opacity = '1';
                    }
                });
        };


        setInterval(forceControlsVisibility, 100);
    });

    window.addEventListener('navigate-to-station', ((event: CustomEvent) => {
        navigateToStation(event.detail);
    }) as EventListener);
});

onUnmounted(() => {
    const windyDiv = document.getElementById('windy');
    if (windyDiv && windyDiv.parentNode) {
        windyDiv.parentNode.removeChild(windyDiv);
    }
    window.removeEventListener('navigate-to-station', ((event: CustomEvent) => {
        navigateToStation(event.detail);
    }) as EventListener);
});
</script>

<template>
    <v-card class="rounded-lg" :height="props.height">
        <div ref="cardContainer" class="map-container">
        </div>
    </v-card>
</template>

<style>
.map-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

#windy {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
}


.custom-layer-controls {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    background: white;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.layer-control-button {
    display: block;
    width: 100%;
    margin: 4px 0;
    padding: 8px 12px;
    border: none;
    background: white;
    cursor: pointer;
    border-radius: 4px;
    text-align: left;
    transition: background-color 0.2s;
}

.layer-control-button:hover {
    background: #f0f0f0;
}


.leaflet-control-container,
.weather-controls,
.controls-floating,
#bottom-ui,
#progress-bar,
#mobile-calendar-wrapper,
#windy-app-timeline {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
    pointer-events: auto !important;
}


.leaflet-bottom {
    opacity: 1 !important;
    visibility: visible !important;
    display: block !important;
    z-index: 1000 !important;
}


.station-popup {
    text-align: center;
}

.station-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}
</style>