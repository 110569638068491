import { UserAppPreference } from '../models/userAppPreference'
export interface UserAppPreferenceStateType {
  userAppPreference: UserAppPreference | null
}

const UserAppPreferenceState: () => UserAppPreferenceStateType = () => ({
  userAppPreference: null,
})

export default UserAppPreferenceState
