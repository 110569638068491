import { AlarmLogStateType } from './state'
import { AlarmLog } from '../models/alarmLog'
export interface AlarmLogGettersType {
  getAlarmLogById: (state: AlarmLogStateType) => AlarmLogStateType['alarmLog']
}

export const AlarmLogGetters = {
  getAlarmLogById: (state: AlarmLogStateType) => {
    return (id: number): AlarmLog | null => {
      return state.alarmLogs.find(log => log.id === id) || null
    }
  },
}
