import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-13f3d8fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_theme_provider, { theme: _ctx.modeTheme }, {
        default: _withCtx(() => [
          (_ctx.isRouterLoaded)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentLayout), { key: 0 }, {
                default: _withCtx(() => [
                  (_ctx.currentLayout === 'loading')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_v_progress_circular, {
                          size: "60",
                          class: "mx-auto my-auto",
                          indeterminate: "",
                          color: "primary"
                        })
                      ]))
                    : (_openBlock(), _createBlock(_Transition, {
                        key: 1,
                        name: "fade",
                        mode: "in-out"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_view)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["theme"])
    ]),
    _: 1
  }))
}