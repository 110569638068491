import { StationFile } from '../models/stationFile'
export interface StationFileStateType {
  stationFiles: StationFile[]
  stationFile: StationFile | null
}

const StationFileState: () => StationFileStateType = () => ({
  stationFiles: [],
  stationFile: null,
})

export default StationFileState
