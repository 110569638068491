import { defineStore } from 'pinia'
import AuthState, { AuthStateType } from './authState'
import { AuthActionsType, AuthActions } from './authActions'
import { AuthGetters } from './authGetters'

export const useAuthStore = defineStore<'auth', AuthStateType, typeof AuthGetters, AuthActionsType>(
  'auth',
  {
    state: AuthState,
    actions: AuthActions,
    getters: AuthGetters,
  },
)
