import unitTypesJson from './baseUnits.json'

export function getConversiontByTypeAndConvertionKey(type: string, conversionKey: string) {
  const unitTypes = unitTypesJson.find(unitType => unitType.type === type)
  const conversion = unitTypes?.values.conversions.find(
    conversion => conversion.key === conversionKey,
  )
  return conversion
}

export function getConversionUnitsByBase(key: string) {
  const convertions = unitTypesJson.find(unitType => unitType.type === key)
  return convertions?.values.conversions
}
