<script lang="ts" setup>
import { ref, provide } from 'vue'
import { defineProps } from 'vue'
//echarts
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart, BarChart } from 'echarts/charts'
import {
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  MarkLineComponent,
} from 'echarts/components'

import VChart, { THEME_KEY } from 'vue-echarts'
// echarts
use([
  LineChart,
  BarChart,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  MarkLineComponent,
  CanvasRenderer,
])

const props = defineProps({
  // dates: {
  //   type: Array,
  //   required: true,
  // },
  // battery: {
  //   type: Array,
  //   required: true,
  // },
  option: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
})
import { watch } from 'vue'
const chartOption = ref(props.option)
watch(
  () => props.option,
  newOption => {
    // Update the chart option when props.option changes
    chartOption.value = newOption
  },
  { deep: true },
)
provide(THEME_KEY, 'light')
</script>
<template>
  <v-chart v-if="chartOption.xAxis" class="chart" :option="chartOption" style="height: 220px; width: 100%" />
</template>
