import { SensorGroup } from '../models/sensorGroup'
export interface SensorGroupStateType {
  sensorGroups: SensorGroup[]
  sensorGroup: SensorGroup | null
}

const SensorGroupState: () => SensorGroupStateType = () => ({
  sensorGroups: [],
  sensorGroup: null,
})

export default SensorGroupState
