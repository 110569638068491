import { storage } from '@/config/firebase'
import { uploadBytes, ref } from 'firebase/storage'

interface FileSet {
  id: string
  url: string
  name: string
}

async function fileInputFileStorage(
  file: File | null,
  route: string,
  id: string,
): Promise<FileSet | undefined> {
  try {
    if (file) {
      const fileSet: FileSet = {
        id: id,
        url: '',
        name: file.name,
      }

      const fr = new FileReader()
      const fileExt = file.name.split('.').pop() || ''

      const path = `${id}.${fileExt}`

      const baseURL = 'https://storage.googleapis.com/aqfcorev1.appspot.com'
      //   if (location.hostname === 'localhost') {
      //     baseURL = 'http://127.0.0.1:9199/aqflowv1.appspot.com'
      //   } else {
      //     baseURL = 'https://storage.googleapis.com/aqflowv1.appspot.com'
      //   }

      const fullPath = `${baseURL}${route}/${path}`

      fr.readAsDataURL(file)

      const refImages = ref(storage, `${route}/${path}`)

      await uploadBytes(refImages, file)

      fileSet.url = fullPath

      return fileSet
    }
  } catch (e) {
    return undefined // Devuelve undefined en caso de error
  }
}

export { fileInputFileStorage }
