import { Location } from '../models/location'
export interface LocationStateType {
  locations: Location[]
  location: Location | null
}

const LocationState: () => LocationStateType = () => ({
  locations: [],
  location: null,
})

export default LocationState
