export interface AuthStateType {
  user: {
    id: string
    name: string
    email: string
  } | null
  errorPassLogin: boolean
  errorPassLoginMsg: string
  isOffline: boolean
  userProfile: {
    id?: string | null
    name: string
    role: string
    email: string
    uid: string
    companyId?: string
  }
  errorLoginMsg: string
  activeUpdateUser: string
}

const AuthState: () => AuthStateType = () => ({
  user: null,
  errorPassLogin: false,
  errorPassLoginMsg: '',
  isOffline: false,
  userProfile: {
    id: '',
    name: '',
    role: '',
    email: '',
    uid: '',
    companyId: '',
  },
  errorLoginMsg: '',
  activeUpdateUser: '',
})

export default AuthState
