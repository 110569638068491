import { defineStore } from 'pinia'
import UserStationPreferenceState, { UserStationPreferenceStateType } from './state'
import { UserStationPreferenceActions, UserStationPreferenceActionsType } from './actions'
import { UserStationPreferenceGetters } from './getters'

export const useUserStationPreferenceStore = defineStore<
  'userStationPreference',
  UserStationPreferenceStateType,
  typeof UserStationPreferenceGetters,
  UserStationPreferenceActionsType
>('userStationPreference', {
  state: UserStationPreferenceState,
  actions: UserStationPreferenceActions,
  getters: UserStationPreferenceGetters,
})
