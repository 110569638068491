import { defineStore } from 'pinia'
import UserAppPreferenceState, { UserAppPreferenceStateType } from './state'
import { UserAppPreferenceActions, UserAppPreferenceActionsType } from './actions'
import { UserAppPreferenceGetters } from './getters'

export const useUserAppPreferenceStore = defineStore<
  'userAppPreference',
  UserAppPreferenceStateType,
  typeof UserAppPreferenceGetters,
  UserAppPreferenceActionsType
>('userAppPreference', {
  state: UserAppPreferenceState,
  actions: UserAppPreferenceActions,
  getters: UserAppPreferenceGetters,
})
