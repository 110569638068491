import { defineStore } from 'pinia'
import AlarmState, { AlarmStateType } from './alarmState'
import { AlarmActions, AlarmActionsType } from './alarmActions'
import { AlarmGetters } from './alarmGetters'

export const useAlarmStore = defineStore<
  'alarm',
  AlarmStateType,
  typeof AlarmGetters,
  AlarmActionsType
>('alarm', {
  state: AlarmState,
  actions: AlarmActions,
  getters: AlarmGetters,
})
