import { defineStore } from 'pinia'
import AlarmLogState, { AlarmLogStateType } from './state'
import { AlarmLogActions, AlarmLogActionsType } from './actions'
import { AlarmLogGetters } from './getters'

export const useAlarmLogStore = defineStore<
  'alarmLog',
  AlarmLogStateType,
  typeof AlarmLogGetters,
  AlarmLogActionsType
>('alarmLog', {
  state: AlarmLogState,
  actions: AlarmLogActions,
  getters: AlarmLogGetters,
})
