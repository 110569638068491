<script lang="ts" setup>
import { defineProps } from 'vue'
import { Station } from '../models/station'
import { useStationFileStore } from '../StationFile/store'
import { useSensorStore } from '@/views/Sensor/store'
import { setChileanDatetimeToStringByISO } from '@/config/utils.js'
import { getSensorIcon, getSensorValueAndConvert, getUnitMeasureViewBySensorId } from '../utils'
import { setGtmOffsetDatetimeString } from '@/config/aux'
import { getFormattedTimeDifferenceFromNow } from '@/config/aux'
import { useUserStationPreferenceStore } from '../UserStationPreference/store'
//data
//store
const stationFileStore = useStationFileStore()
const userStationPreferenceStore = useUserStationPreferenceStore()
const sensorStore = useSensorStore()

const props = defineProps<{
  stations: Station[]
}>()

//lifecycle
</script>
<template>
  <v-row class="mt-3 pa-3">
    <v-col cols="12" sm="12" md="12" lg="3" xl="3" xxl="3" v-for="station in props.stations" :key="station.id">
      <v-card class="station-card bg-background card-rounded" elevation="2" min-height="450px">
        <img v-if="station?.images?.length > 0" :src="station.images[0].src" alt="Estación" class="station-image img"
          height="200" />
        <img v-else src="https://www.renkeer.com/wp-content/uploads/2024/01/agricultural-weather-station.jpg"
          alt="Estación" class="station-image img" height="200" />
        <v-row class="mt-8">
          <v-col>
            <div class="px-6 py-6 text-center">
              <h1 class="mb-0 text-primary font-weight-bolder text-typo">
                {{ station.name }}
              </h1>
              <p class="mb-0 text-sm text-typo">
                Ultima sincronización
                {{
                  station.lastMeasurement
                    ? setChileanDatetimeToStringByISO(
                      setGtmOffsetDatetimeString(
                        station.lastMeasurement?.date,
                        stationFileStore.getStationFileByStationId(station.id)?.timestampGmt ||
                        '-3',
                      ),
                    )
                    : 'Sin fecha'
                }}
              </p>
              <h6 class="text-center" v-if="station.lastMeasurement?.date">
                Tomados hace {{ getFormattedTimeDifferenceFromNow(station.lastMeasurement?.date) }}
              </h6>
            </div>
          </v-col>

        </v-row>
        <v-row class="pa-5">
          <v-col>
            <v-row>
              <v-col cols="6" v-for="(sensor, i) in stationFileStore.getOrderedColumnsByPositionAndStationId(
                station.id,
                'primary',
              )" :key="i">
                <div class="mt-3 ">
                  <div class="d-flex justify-center">
                    <v-icon>
                      {{ getSensorIcon(sensorStore.getFieldSensorById(sensor.sensorId) || '') }}
                    </v-icon>
                  </div>
                  <h4 class="text-center mx-2">{{ sensor.name }}</h4>
                  <div class="text-center">
                    <span>
                      {{
                        getSensorValueAndConvert(
                          station?.lastMeasurement,
                          sensorStore.getSensorById(sensor.sensorId),
                          userStationPreferenceStore.getUserStationPreferenceByStationId(station?.id)
                            ? userStationPreferenceStore.getUserStationPreferenceByStationId(
                              station?.id,
                            )?.columns
                            : stationFileStore.getOrderedColumnsByPositionAndStationId(
                              station?.id,
                              'primary',
                            ),
                        )
                      }}

                      {{
                        getUnitMeasureViewBySensorId(
                          sensor.sensorId,
                          userStationPreferenceStore.getUserStationPreferenceByStationId(station?.id)
                            ? userStationPreferenceStore.getUserStationPreferenceByStationId(
                              station?.id,
                            )?.columns
                            : stationFileStore.getOrderedColumnsByPositionAndStationId(
                              station?.id,
                              'primary',
                            ),
                          sensorStore.sensors,
                        )
                      }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<style scoped>
.station-card {
  overflow: visible;
  position: relative;
  padding-top: 100px;
  margin-top: 80px;
}

.station-image {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  width: 90%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-content {
  padding-top: 60px;
}

.status-chips {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 16px;
}

.station-title {
  text-align: center;
  font-weight: bold;
  margin: 0;
}

.station-description {
  text-align: center;
  color: grey;
}

.station-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  font-size: 0.9rem;
  color: grey;
}

.img {
  display: block;

  object-fit: cover;

  border-radius: 8px;
}
</style>
