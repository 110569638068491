import { convertDatesToISO } from '@/config/aux'
import { ChartSetting } from '../models/chartSetting'
import { ChartSettingStateType } from './state'
import axios from 'axios'

export interface ChartSettingActionsType {
  getChartSettings(): Promise<void>
  postChartSetting(chartSetting: ChartSetting): Promise<void>
  putChartSetting(chartSetting: ChartSetting): Promise<void>
  deleteChartSetting(id: string): Promise<void>
}

export const ChartSettingActions = {
  async getChartSettings(this: ChartSettingStateType) {
    try {
      this.chartSettings = []
      const query = 'https://aqfcore-api.aquaflow.cl/chartsettings'
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((chartSetting: ChartSetting) => {
            this.chartSettings.push(chartSetting)
          })
        }
      })
    } catch (error) {
      console.error('Error getting ChartSettings', error)
    }
  },
  async postChartSetting(this: ChartSettingStateType, chartSetting: ChartSetting) {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/chartsettings'
      const data = convertDatesToISO(chartSetting.toPlainObject())

      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.chartSettings.push(chartSetting)
        }
      })
    } catch (error) {
      console.error('Error posting ChartSetting', error)
    }
  },
  async putChartSetting(this: ChartSettingStateType, chartSetting: ChartSetting) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/chartsettings`

      const data = convertDatesToISO(chartSetting.toPlainObject())

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.chartSettings = this.chartSettings.map(a => (a.id === chartSetting.id ? chartSetting : a))
          }
        })
    } catch (error) {
      console.error('Error updating ChartSetting', error)
    }
  },
  async deleteChartSetting(this: ChartSettingStateType, id: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/chartsettings/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.chartSettings = this.chartSettings.filter(a => a.id !== chartSetting.id)
        }
      })
    } catch (error) {
      console.error('Error deleting ChartSetting', error)
    }
  },
}
