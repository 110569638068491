import { convertDatesToISO } from '@/config/aux'
import { Sensor } from '../models/sensor'
import { SensorStateType } from './state'
import axios from 'axios'

export interface SensorActionsType {
  getSensors(): Promise<void>
  postSensor(sensor: Sensor): Promise<void>
  putSensor(sensor: Sensor): Promise<void>
  deleteSensor(id: string): Promise<void>
}

export const SensorActions = {
  async getSensors(this: SensorStateType) {
    try {
      this.sensors = []
      const query = 'https://aqfcore-api.aquaflow.cl/sensors'
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((sensor: Sensor) => {
            this.sensors.push(sensor)
          })
        }
      })
    } catch (error) {
      console.error('Error getting Sensors', error)
    }
  },
  async postSensor(this: SensorStateType, sensor: Sensor) {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/sensors'

      const data = convertDatesToISO(sensor.toPlainObject())

      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.sensors.push(sensor)
        }
      })
    } catch (error) {
      console.error('Error posting Sensor', error)
    }
  },
  async putSensor(this: SensorStateType, sensor: Sensor) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/sensors`
      const data = convertDatesToISO(sensor)
      //const dataJson = JSON.stringify(data)

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.sensors = this.sensors.map(a => (a.id === sensor.id ? sensor : a))
          }
        })
    } catch (error) {
      console.error('Error updating Sensor', error)
    }
  },
  async deleteSensor(this: SensorStateType, id: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/sensors/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.sensors = this.sensors.filter(a => a.id !== sensor.id)
        }
      })
    } catch (error) {
      console.error('Error deleting Sensor', error)
    }
  },
}
