<script lang="ts" setup>
import { computed, defineProps, onMounted, ref, watch } from 'vue'
import { reactive } from 'vue'

//models
import { Station } from '../models/station'

// stores
import { useStationFileStore } from '../StationFile/store'
import { useSensorStore } from '@/views/Sensor/store'
import { useChartSettingStore } from '@/views/ChartSetting/store'
import { useMeasurementStore } from '@/views/Measurement/store'
import { useStationStore } from '../store'
import { useAuthStore } from '@/views/Authentication/store'
import { useUserStationPreferenceStore } from '../UserStationPreference/store'
import { useThemeStore } from '@/stores/theme'
//others imports

import { buildChartsV2 } from '@/views/Measurement/buildChartV2'
import { setChileanDatetimeToStringByISO } from '@/config/utils.js'
import { getSensorIcon, getSensorValue, getUnitMeasureViewBySensorId } from '../utils'
import { getConversiontByTypeAndConvertionKey } from '@/views/Sensor/utils'
import { getSensorValueAndConvert } from '../utils'
import DynamicChartCompact from './charts/DynamicChartCompact.vue'
import { getFormattedTimeDifferenceFromNow, setGtmOffsetDatetimeString } from '@/config/aux'
import { convertUnit } from '@/views/Measurement/normalizationMeasurement'

//props
const props = defineProps<{
  stations: Station[]
}>()
//store
const stationFileStore = useStationFileStore()
const sensorStore = useSensorStore()
const chartSettingStore = useChartSettingStore()
const measurementStore = useMeasurementStore()
const stationStore = useStationStore()
const authStore = useAuthStore()
const userStationPreferenceStore = useUserStationPreferenceStore()
const themeStore = useThemeStore()
//data an reactive
const resumeStations = ref([])
const chartsMap = reactive([])
const loadingCharts = ref(false)
//computed
const chartSettings = computed(() => chartSettingStore.chartSettings)
const measurementRealTime = computed(() => measurementStore.measurementRealTime)
const userProfile = computed(() => authStore.userProfile)
const sensors = computed(() => sensorStore.sensors)
const calculateDates = () => {
  const now = new Date()

  const chileDate = new Date(now.toLocaleString('en-US', { timeZone: 'America/Santiago' }))

  const today = new Date(chileDate.toISOString().substring(0, 10) + 'T23:59:50')

  const twoDaysAgo = new Date(today)
  twoDaysAgo.setDate(today.getDate() - 2)

  const formatDate = date => date.toISOString().split('T')[0] // Format YYYY-MM-DD
  return { initDate: formatDate(twoDaysAgo), endDate: formatDate(today) }
}

const currentTheme = computed(() => themeStore.currentTheme)
const getMeasurements = async (stationId: string) => {
  const { initDate, endDate } = calculateDates()
  let measurements = await measurementStore.getMeasurementsByStationAndFilters({
    stationId: stationId,
    initDate: initDate,
    endDate: endDate,
    range: '10mins',
    gtm: stationFileStore.getStationFileByStationId(stationId)?.timestampGmt || '-3',
  })
  return measurements
}

const createChart = async (chartSetting, stationId) => {
  const measurements = await getMeasurements(stationId)
  let userStationPreference = null
  // cargar userPreference si es role usuario y tiene
  let columns = stationFileStore.getOrderedColumnsByPositionAndStationId(stationId, 'primary')
  if (userProfile?.value?.role == 'user') {
    userStationPreference =
      await userStationPreferenceStore.getUserStationPreferenceByStationFileId(
        stationFileStore.getStationFileByStationId(stationId).id,
        userProfile.value.id,
      )
    columns.forEach(column => {
      let columnPreference = userStationPreference.columns.find(
        columnPreference => columnPreference.sensorId == column.sensorId,
      )
      if (columnPreference) {
        column.unitMeasureView = columnPreference.unitMeasureView
      }
    })
  }

  const chart = await buildChartsV2(chartSetting, measurements, sensors.value, columns, false, currentTheme.value === 'myCustomDarkTheme')
  const chartId = `chart-${chartSetting.id}-${stationId}`
  chartsMap[chartId] = chart

  return chartId // Devolvemos el ID del gráfico para usarlo en la vista
}

const createResumenStations = async () => {
  let resumenStations = []
  loadingCharts.value = true
  for (const station of props.stations) {
    const columns = stationFileStore.getOrderedColumnsByPositionAndStationId(
      station.id,
      'primary',
    )

    //primero opcion busca level caudal
    //segunda opcion caudal
    // tercera opcion level
    let chartSettingSearch
    const searchCaudal = columns.some(sensor =>
      sensor.sensorId.includes(sensorStore.getSensorIdByField('caudal') || ''),
    )
    const searchLevel = columns.some(sensor =>
      sensor.sensorId.includes(sensorStore.getSensorIdByField('level') || ''),
    )
    if (searchCaudal && searchLevel) {
      chartSettingSearch = chartSettings.value.find(setting =>
        setting.sensorGroupIds?.includes('146b7331823b43f19b1951d44d57e4d5'),
      )
    } else {
      if (searchLevel) {
        chartSettingSearch = chartSettings.value.find(setting =>
          setting.sensorIds?.includes(sensorStore.getSensorIdByField('level') || ''),
        )
      } else {
        chartSettingSearch = chartSettings.value.find(setting =>
          setting.sensorIds?.includes(sensorStore.getSensorIdByField('caudal') || ''),
        )
      }
    }
    let chartId = null
    if (searchLevel) {
      chartId = await createChart(chartSettingSearch, station.id)
    }
    //le empujara las preferencias de userStationPreference si es user
    let userStationPreference = null
    if (userProfile.value.role == 'user') {
      userStationPreference =
        await userStationPreferenceStore.getUserStationPreferenceByStationFileId(
          stationFileStore.getStationFileByStationId(station.id).id,
          userProfile.value.id,
        )
    }
    resumenStations.push({
      station,
      columns,
      chartId,
      userStationPreference: userStationPreference,
    })
  }
  resumeStations.value = resumenStations
  loadingCharts.value = false
}

watch(
  measurementRealTime,
  measurement => {
    if (measurement) {
      Object.keys(chartsMap).forEach(chartId => {
        const [_, sensorId, stationId] = chartId.split('-')
        if (stationId === measurement.stationId) {
          const chart = chartsMap[chartId]

          chart.series.forEach(serie => {
            let sensorFieldName = sensorStore.getFieldSensorById(serie.id)

            if (sensorFieldName && measurement[sensorFieldName] !== undefined) {
              let newValue = null
              if (userProfile.value.role == 'user') {
                let userStationPreference =
                  userStationPreferenceStore.getUserStationPreferenceByStationFileId(
                    stationFileStore.getStationFileByStationId(measurement.stationId).id,
                    userProfile.value.id,
                  )
                if (userStationPreference) {
                  let columnPreference = userStationPreference.columns.find(
                    column => column.sensorId == serie.id,
                  )
                  if (columnPreference) {
                    newValue = convertUnit(
                      measurement[sensorFieldName],
                      sensorStore.getSensorById(serie.id).unitMeasureNormalized,
                      columnPreference.unitMeasureView,
                      sensorStore.getSensorById(serie.id).unitType,
                    )
                  }
                }
              } else {
                newValue = convertUnit(
                  measurement[sensorFieldName],
                  sensorStore.getSensorById(serie.id).unitMeasureNormalized,
                  stationFileStore.getColumnByStationIdAndSensorId(
                    measurement.stationId,
                    serie.id,
                  ).unitMeasureView,
                  sensorStore.getSensorById(serie.id).unitType,
                )
              }

              const newDate = new Date(measurement.date) // Convertir a Date
              // Validar que la fecha sea válida
              if (isNaN(newDate)) {
                console.error('Fecha inválida:', measurement.date)
                return
              }

              // Encuentra la posición correcta en función de la fecha
              const insertIndex = chart.xAxis.data.findIndex(date => {
                const existingDate = new Date(date)
                return existingDate > newDate
              })

              if (insertIndex === -1) {
                // Si no hay fechas mayores, inserta al final
                serie.data.push(newValue)
                chart.xAxis.data.push(measurement.date)
              } else {
                // Inserta en la posición encontrada
                serie.data.splice(insertIndex, 0, newValue)
                chart.xAxis.data.splice(insertIndex, 0, measurement.date)
              }
            }
          })
        }
      })
    }
  },
  { deep: true, immediate: true },
)

//lifecycle

//watch
//cuando cambie los mesaurement gnere los resumeStations
watch(props.stations, async newStations => {
  createResumenStations()
})
onMounted(() => {
  createResumenStations()
})
</script>
<template>
  <div>
    <v-row class="mt-3 pa-5">
      <v-col cols="12" v-for="(resume, i) in resumeStations" :key="i" class="mx-2">
        <v-card class="bg-background card-rounded" elevation="2" c>
          <v-row class="my-2 ">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3" xxl="3">
              <div class="px-6 py-6 text-center mt-8">
                <h1 class="mb-0 text-text-primary font-weight-bolder text-typo">
                  {{ resume.station?.name }}
                </h1>
                <p class="mb-0 text-sm text-typo">
                  Ultima sincronización
                  {{
                    resume.station?.lastMeasurement?.date
                      ? setChileanDatetimeToStringByISO(
                        setGtmOffsetDatetimeString(
                          resume.station?.lastMeasurement?.date,
                          stationFileStore.getStationFileByStationId(resume.station?.id)
                            ?.timestampGmt || '-3',
                        ),
                      )
                      : 'Sin fecha'
                  }}
                </p>
                <p>
                  Hace
                  {{
                    getFormattedTimeDifferenceFromNow(
                      setGtmOffsetDatetimeString(
                        resume.station?.lastMeasurement?.date,
                        stationFileStore.getStationFileByStationId(resume.station?.id)
                          ?.timestampGmt,
                      ),
                    )
                  }}
                </p>
                <div class="d-flex justify-center">
                  <v-chip class="my-1" :color="resume.station.active ? 'success' : 'error'" label
                    :text="resume.station.active ? 'Estación Activa' : 'Estación desactiva'" />
                </div>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" xxl="4">
              <v-row>
                <v-col cols="3" v-for="(sensor, i) in resume.columns" :key="i">
                  <div class="mt-3" v-tooltip="`${getConversiontByTypeAndConvertionKey(sensorStore.getSensorById(sensor.sensorId)?.unitType, resume?.userStationPreference ? resume.userStationPreference.columns.find(column => column.sensorId === sensor.sensorId)?.unitMeasureView : sensor.unitMeasureView)?.name}`
                    ">
                    <h4 class="text-center">{{ sensor.name }}</h4>
                    <div class="d-flex justify-center">
                      <v-icon>
                        {{ getSensorIcon(sensorStore.getFieldSensorById(sensor.sensorId) || '') }}
                      </v-icon>
                    </div>
                    <div class="text-center">
                      <span>
                        {{
                          getSensorValueAndConvert(
                            resume?.station?.lastMeasurement,
                            sensorStore.getSensorById(sensor.sensorId),
                            resume?.userStationPreference
                              ? resume?.userStationPreference?.columns
                              : stationFileStore.getOrderedColumnsByPositionAndStationId(
                                station?.id,
                                'primary',
                              ),
                          )
                        }}

                        {{
                          getUnitMeasureViewBySensorId(
                            sensor.sensorId,
                            resume?.userStationPreference
                              ? resume?.userStationPreference?.columns
                              : stationFileStore.getOrderedColumnsByPositionAndStationId(
                                station?.id,
                                'primary',
                              ),
                            sensors,
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="11" sm="11" md="11" lg="5" xl="5" xxl="5">
              <div>
                <DynamicChartCompact :option="chartsMap[resume.chartId]" title="Nivel" />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="loadingCharts" class="pa-5">
      <v-col cols="12" v-for="n in 4" :key="n" class="mx-2">
        <v-card class="bg-background card-rounded" elevation="2">
          <v-row class="my-2">
            <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3" xxl="3">
              <v-skeleton-loader class="bg-background" width="100%" height="220px" type="table-heading, article " />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5" xxl="5">
              <v-skeleton-loader class="bg-background" width="100%" height="220px" type="article" />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" xxl="4">
              <v-skeleton-loader class="bg-background rounded-xl mx-3" width="90%" height="220px" type="image" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.full {
  width: 100%;
}
</style>
