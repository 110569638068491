import { UserStationPreference } from '../models/userStationPreference'
export interface UserStationPreferenceStateType {
  userStationPreferences: UserStationPreference[]
  userStationPreference: UserStationPreference | null
}

const UserStationPreferenceState: () => UserStationPreferenceStateType = () => ({
  userStationPreferences: [],
  userStationPreference: null,
})

export default UserStationPreferenceState
