<script lang="ts" setup>
import { defineProps } from 'vue'
import { Station } from '../models/station'
//data
//store

const props = defineProps<{
  stations: Station[]
}>()

//lifecycle
</script>
<template>

  <v-card class="custom-card bg-primary">
    <div class="bubbles">
      <div class="bubble large"></div>
      <div class="bubble small"></div>
    </div>
    <div class="card-content">
      <h1>{{ props.stations?.length }} {{ props.stations?.length == 1 ? 'Estación activa' : 'Estaciones activas' }}</h1>
      <p>Total {{ props.stations?.length }}</p>
    </div>
  </v-card>
</template>
<style>
.custom-card {

  color: white;
  padding: 24px;
  height: 100%;
  position: relative;
  border-radius: 16px;
}

.bubbles {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.bubble {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.bubble.large {
  width: 100px;
  height: 100px;
  top: -20px;
  right: -20px;
}

.bubble.small {
  width: 60px;
  height: 60px;
  top: 40px;
  right: 30px;
}

.card-content {
  z-index: 1;
  margin-top: 40px;
  position: relative;
  text-align: left;
}
</style>