import { convertDatesToISO } from '@/config/aux'
import { SensorGroup } from '../models/sensorGroup'
import { SensorGroupStateType } from './state'
import axios from 'axios'

export interface SensorGroupActionsType {
  getSensorGroups(): Promise<void>
  postSensorGroup(sensorGroup: SensorGroup): Promise<void>
  putSensorGroup(sensorGroup: SensorGroup): Promise<void>
  deleteSensorGroup(id: string): Promise<void>
}

export const SensorGroupActions = {
  async getSensorGroups(this: SensorGroupStateType) {
    try {
      this.sensorGroups = []
      const query = 'https://aqfcore-api.aquaflow.cl/sensorgroups'
      await axios.get(query).then(response => {
        if (response.status === 200) {
          response.data.forEach((sensorGroup: SensorGroup) => {
            this.sensorGroups.push(sensorGroup)
          })
        }
      })
    } catch (error) {
      console.error('Error getting SensorGroups', error)
    }
  },
  async postSensorGroup(this: SensorGroupStateType, sensorGroup: SensorGroup) {
    try {
      const query = 'https://aqfcore-api.aquaflow.cl/sensorgroups'
      const data = convertDatesToISO(sensorGroup.toPlainObject())

      await axios.post(query, data).then(response => {
        if (response.status === 201) {
          // this.sensorGroups.push(sensorGroup)
        }
      })
    } catch (error) {
      console.error('Error posting SensorGroup', error)
    }
  },
  async putSensorGroup(this: SensorGroupStateType, sensorGroup: SensorGroup) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/sensorgroups`
      const data = convertDatesToISO(sensorGroup.toPlainObject())
      //const dataJson = JSON.stringify(data)

      await axios
        .put(query, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.status === 200) {
            //this.sensorGroups = this.sensorGroups.map(a => (a.id === sensorGroup.id ? sensorGroup : a))
          }
        })
    } catch (error) {
      console.error('Error updating SensorGroup', error)
    }
  },
  async deleteSensorGroup(this: SensorGroupStateType, id: string) {
    try {
      const query = `https://aqfcore-api.aquaflow.cl/sensorgroups/${id}`
      await axios.delete(query).then(response => {
        if (response.status === 200) {
          //this.sensorGroups = this.sensorGroups.filter(a => a.id !== sensorGroup.id)
        }
      })
    } catch (error) {
      console.error('Error deleting SensorGroup', error)
    }
  },
}
